import "../../assets/scss/components/SessionDataGrid.scss";
import type { Database } from "src/utils/DatabaseDefinitions";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  ListItemText,
  Popover,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import FilterDownArrowIcon from "../../assets/img/views/dashboard-sessions/filter-icon.svg";
import DeleteOutlined from "../../assets/img/views/dashboard-sessions/DeleteOutlined.svg";
import ActionsOutlined from "../../assets/img/views/dashboard-sessions/ActionsOutlined.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import { Close } from "@mui/icons-material";

import { Calendar, DateRange } from "react-date-range";
import "../../assets/scss/DatePicker.scss";
import dayjs from "dayjs";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  gridClasses,
  type GridColDef,
  type GridRenderCellParams,
  type GridRowId,
  type GridSortModel,
} from "@mui/x-data-grid";

import { withReference } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import SuspenseLoader from "src/components/SuspenseLoader";
import { type Action, useSupabaseReducer } from "src/utils/SupaReducer";
import { Link } from "react-router-dom";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import convert from "convert-units";
import { match, P } from "ts-pattern";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { useTitle } from "../customLayouts/DashboardLayout";
import type { DataGridFilter, ValueFilter } from "src/types/DataGridFilters";
import CompareModal from "./CompareModal";
import { WithCache } from "src/contexts/CacheContext";
import { useUser } from "src/components/Authenticated";

type DatabaseSessions = Database["public"]["Tables"]["session"]["Row"] & {
  ride_types: { name: string };
  athlete_bikes: { bike_types: { name: string }; bike_name: string };
};

const sessionsColumns = [
  "id",
  "date",
  "recording_name",
  "total_distance",
  "total_time",
  "average_power",
  "average_speed",
  "ride_types",
  "provider",
] as const;

type SessionsColumns = typeof sessionsColumns[number];

type Sessions = Array<Pick<DatabaseSessions, SessionsColumns>>;

type NewDateFilterComponentProps = {
  onApply: (filter: DataGridFilter<Date>) => void;
  onClose: () => void;
};

function NewDateFilterComponent(props: NewDateFilterComponentProps) {
  const [operation, setOperation] =
    useState<ValueFilter<Date>["operation"]>("less-than-equal");

  //Calendar
  const [date, setDate] = useState<Date>();

  //Date Range

  const [state, setState] = useState<{
    startDate: Date;
    endDate: Date;
    key: "selection";
  }>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleOnChange = useCallback(
    ({ selection }: { selection: typeof state }) => {
      setState(selection);
    },
    [],
  );

  return (
    <>
      <Box sx={{ p: 1, background: "#201B20" }} className="date-picker-wrapper">
        <Box
          sx={{
            display: "flex",
            mb: "10px",
            mt: "16px",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            size="small"
            onClick={(_) => {
              setOperation("less-than-equal");
            }}
            variant={operation === "less-than-equal" ? "text" : "outlined"}
          >
            Before
          </Button>
          <Button
            size="small"
            onClick={() => {
              setOperation("between");
            }}
            variant={operation === "between" ? "text" : "outlined"}
          >
            Between
          </Button>

          <Button
            size="small"
            onClick={(_) => {
              setOperation("greater-than-equal");
            }}
            variant={operation === "greater-than-equal" ? "text" : "outlined"}
          >
            After
          </Button>
        </Box>

        {operation === "between" ? (
          <DateRange
            onChange={handleOnChange}
            ranges={!!state ? [state] : []}
            editableDateInputs
            maxDate={new Date()}
          />
        ) : (
          <Calendar
            onChange={(date) => {
              setDate(date);
            }}
            date={date}
            maxDate={new Date()}
          />
        )}

        <Box sx={{ display: "flex", pt: 1, mb: "24px", ml: "20px" }}>
          <Button
            size="small"
            variant="contained"
            onClick={(_) => {
              props.onApply(
                operation === "between"
                  ? {
                      kind: "value-filter",
                      field: "date",
                      operation,
                      startValue: state.startDate,
                      endValue: state.endDate,
                    }
                  : {
                      kind: "value-filter",
                      field: "date",
                      operation,
                      value: date,
                    },
              );
              props.onClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </>
  );
}

type NewNumericFilterComponentProps = {
  onApply: (filter: DataGridFilter<number>) => void;
  onClose: () => void;
  metric: string;
  fieldName: string;
};

function NewNumericFilterComponent(props: NewNumericFilterComponentProps) {
  const [operation, setOperation] =
    useState<ValueFilter<number>["operation"]>("between");

  const [min, setMin] = useState<number | "">("");
  const [max, setMax] = useState<number | "">("");

  return (
    <Box
      sx={{
        background: "#201B20",
        padding: "1em",
        height: "250px",
        borderRadius: "4px",
      }}
    >
      <Box>
        <Typography variant="body2">Operator</Typography>
        <Box
          sx={{
            display: "flex",
            mb: "24px",
            mt: "12px",
            gap: 1,
          }}
        >
          <Button
            size="small"
            onClick={() => {
              setOperation("between");
            }}
            variant={operation === "between" ? "text" : "customDisabled"}
          >
            <Typography>Between</Typography>
          </Button>
          <Button
            size="small"
            onClick={(_) => {
              setOperation("less-than-equal");
            }}
            variant={
              operation === "less-than-equal" ? "text" : "customDisabled"
            }
          >
            <Typography>Less than equal</Typography>
          </Button>

          <Button
            size="small"
            onClick={(_) => {
              setOperation("greater-than-equal");
            }}
            variant={
              operation === "greater-than-equal" ? "text" : "customDisabled"
            }
          >
            <Typography>Greater than equal</Typography>
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: { md: "space-between", xs: "space-around" },
          }}
        >
          {operation === "between" ? (
            <>
              <Box>
                <Typography>Value ({props.metric})</Typography>
                <TextField
                  placeholder="Min"
                  value={min}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setMin(Number(e.target.value));
                    }
                  }}
                  sx={{ width: { xl: "200px", md: "180px", xs: "10rem" } }}
                  InputProps={{
                    sx: {
                      width: { md: "100%", xs: "90%" },
                    },
                  }}
                />
              </Box>
              <Box>
                <Typography>Value ({props.metric})</Typography>
                <TextField
                  placeholder="Max"
                  value={max}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (e.target.value === "" || regex.test(e.target.value)) {
                      setMax(Number(e.target.value));
                    }
                  }}
                  sx={{ width: { xl: "200px", md: "180px", xs: "10rem" } }}
                  InputProps={{
                    sx: {
                      width: { md: "100%", xs: "90%" },
                    },
                  }}
                />
              </Box>
            </>
          ) : (
            <Box>
              <Typography>Value ({props.metric})</Typography>
              <TextField
                placeholder="Value"
                value={min}
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    setMin(Number(e.target.value));
                  }
                }}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", pt: "24px", mb: "24px" }}>
          <Button
            size="small"
            variant="contained"
            disabled={operation === "between" && min > max}
            onClick={(_) => {
              props.onApply(
                operation === "between"
                  ? {
                      kind: "value-filter",
                      field: props.fieldName,
                      operation,
                      startValue: Number(min),
                      endValue: Number(max),
                    }
                  : {
                      kind: "value-filter",
                      field: props.fieldName,
                      operation,
                      value: Number(min),
                    },
              );
              props.onClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

type MultiSelectFilterComponentProps = {
  onApply: (filter: DataGridFilter) => void;
  onClose: () => void;
  fieldName?: string;
  options: Array<{
    label: string;
    value: string;
  }>;
  clearTrigger: boolean;
};

function MultiSelectFilterComponent(props: MultiSelectFilterComponentProps) {
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);

  useEffect(() => {
    setSelectedOptions([]);
  }, [props.clearTrigger]);

  return (
    <Box
      sx={{
        background: "#201B20",
        height: "188px",
        padding: "1em",
        borderRadius: "4px",
      }}
    >
      <Box>
        <Typography variant="body2">Operator</Typography>
        <Box
          sx={{
            display: "flex",
            mb: "48px",
            mt: "12px",
            gap: 1,
            justifyContent: "space-between",
          }}
        >
          {props.options.map((option) => (
            <Button
              size="small"
              key={option.value}
              variant={
                selectedOptions.includes(option.value)
                  ? "text"
                  : "customDisabled"
              }
              onClick={(_) =>
                setSelectedOptions((opts) =>
                  opts.includes(option.value)
                    ? opts.filter((opt) => opt !== option.value)
                    : opts.concat([option.value]),
                )
              }
            >
              <Typography>{option.label}</Typography>
            </Button>
          ))}
        </Box>

        <Box sx={{ display: "flex", mb: "24px" }}>
          <Button
            size="small"
            variant="contained"
            onClick={(_) => {
              props.onApply({
                kind: "multiselect-filter",
                field: props.fieldName,
                values: selectedOptions,
              });
              props.onClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export type ShowHideColumnsComponentProps = {
  allColumns: Array<string>;
  defaultSelectedColumns: Array<string>;
  onChange: (selectedColumns: Array<string>) => void;
};

function ShowHideColumnsComponent(props: ShowHideColumnsComponentProps) {
  const [selectedColumns, setSelectedColumns] = useState(
    props.defaultSelectedColumns,
  );

  useEffect(() => {
    props.onChange(selectedColumns);
  }, [selectedColumns]);

  return (
    <Box sx={{ p: 1, background: "#201B20" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: "10px",
          mt: "16px",
        }}
      >
        {props.allColumns.map((option, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              width: "20.625rem",
            }}
            onClick={(_) =>
              selectedColumns.includes(option)
                ? setSelectedColumns(
                    selectedColumns.filter((op) => op !== option),
                  )
                : setSelectedColumns(selectedColumns.concat([option]))
            }
          >
            <Checkbox checked={selectedColumns.includes(option)} />
            <ListItemText primary={option} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default function SessionsDataTable() {
  const user = useUser();
  const supabase = useSupabase();

  const [, setTitle] = useTitle();

  useEffect(() => {
    setTitle("Sessions");
  }, []);

  const [sessions, dispatch, count] = useSupabaseReducer(
    supabase
      .from("session")
      .select(
        "id,date,recording_name,total_distance,total_time,average_power,average_speed,ride_types!inner(name),provider,average_cadence,max_heartrate,max_power,elevation_gain,athlete_bikes(bike_name,bike_types(name)),average_pedal_balance_left,calibration_angle",
        { count: "exact" },
      )
      .eq("athlete_id", user.id),
    10,
  );

  useEffect(() => {
    dispatch({
      kind: "MODIFY_CALL",
      builder: (builder) => builder.order("date", { ascending: false }),
      pageSize: 10,
    });
    dispatch({
      kind: "FETCH",
    });
  }, []);

  return (
    <>
      {sessions === "unloaded" ? (
        <SuspenseLoader />
      ) : (
        <SessionsDataTableInner
          sessions={sessions as any}
          dispatch={dispatch}
          count={count}
        />
      )}
    </>
  );
}

type SessionsDataTableInnerProps = {
  sessions: Sessions;
  dispatch: React.Dispatch<Action<any, any, any>>;
  count: number;
};

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
      border-radius: ${theme.general.borderRadius};

      .MuiOutlinedInput-root {
        width:15.5rem
      } 
    `,
);

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "#2C262D",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#201B20",
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    "&:hover": {
      backgroundColor: "#342C35",
    },
  },
}));

function SessionsDataTableInner(props: SessionsDataTableInnerProps) {
  const units = useUnits();
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const customBreakpoint = useMediaQuery(theme.breakpoints.down("custom"));
  const isMobileScreen = useMediaQuery("(max-width:480px)");
  const isSmallTablet = useMediaQuery("(max-width:710px)");

  const supabase = useSupabase();

  const deleteSessionPromise = useCallback(
    (session_id: string) =>
      supabase
        .from("session")
        .delete()
        .eq("id", session_id)
        .throwOnError()
        .then((_) => session_id),
    [supabase],
  );

  const deleteSessionCall = useAsyncState<string>();

  const [fieldToDelete, setFieldToDelete] =
    useState<GridRenderCellParams<any, any, any>>(null);

  const allColumns: Array<GridColDef> = useMemo(
    () => [
      {
        field: "date",
        headerName: "Date",
        flex: 1,
        renderCell: (field) =>
          withReference(new Date(field.value), (date) => (
            <Box
              sx={{
                width: "100%",
                textDecoration: "none",
                color: "inherit",
              }}
              component={Link}
              to={`/dashboard/session/${field.row.id}/overview`}
            >
              <Box>
                <Typography variant="h4">
                  {date.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </Typography>
              </Box>
            </Box>
          )),
      },
      {
        field: "recording_name",
        headerName: "Title",
        flex: 1,
        filterable: false,
        editable: false,
        // preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        //   const hasError = params.props.value.length < 1;
        //   if (!hasError) {
        //     if ("fire" in updateRecordingNameCall) {
        //       updateRecordingNameCall.fire(async () =>
        //         updateRecordingNamePromise(
        //           params.id.toString(),
        //           params.props.value,
        //         ),
        //       );
        //     }
        //   }
        //   return { ...params.props, error: hasError };
        // },
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Tooltip title={field.value}>
              <Box
                sx={{
                  width: "100%",
                  textDecoration: "none",
                  color: "inherit",
                }}
                component={Link}
                to={`/dashboard/session/${field.row.id}/overview`}
              >
                <Typography
                  variant="h4"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {field.value}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        ),
      },
      {
        field: "total_distance",
        headerName: "Distance",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              textDecoration: "none",
              color: "inherit",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Box>
              <Typography variant="h4">
                {units === "metric"
                  ? `${field?.value?.toFixed(2) ?? "0"} km`
                  : `${convert(field?.value)
                      ?.from("km")
                      .to("mi")
                      ?.toFixed(2)} mi`}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "total_time",
        headerName: "Duration",
        flex: 1,
        filterable: false,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              textDecoration: "none",
              color: "inherit",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Box>
              <Typography variant="h4">
                {withReference(
                  Number((field.value / 1000).toFixed(0)),
                  (seconds) => {
                    const hours = Math.floor(seconds / 3600);
                    const minutes = Math.floor((seconds % 3600) / 60);
                    const secs = (seconds % 3600) % 60;
                    return `${String(hours).padStart(2, "0")}:${String(
                      minutes,
                    ).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
                  },
                )}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "average_speed",
        headerName: "Avg Speed",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              textDecoration: "none",
              color: "inherit",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Box>
              <Typography variant="h4">
                {!!field?.value
                  ? units === "metric"
                    ? `${field?.value?.toFixed(1)} kph`
                    : `${((field?.value ?? 0) * 0.6213711922)?.toFixed(1)} mph`
                  : "-"}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "average_power",
        headerName: "Avg Power",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              textDecoration: "none",
              color: "inherit",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Box>
              <Typography variant="h4">
                {!!field.value ? `${field.value.toFixed(0)} Watts` : "-"}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "ride_types",
        headerName: "Type of Ride",
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        hideSortIcons: true,
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "15px",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Box>
              <Typography variant="h4">{field.value.name}</Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "provider",
        headerName: "Provider",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "15px",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Typography variant="h4">{field.value}</Typography>
          </Box>
        ),
      },
      {
        field: "calibration_angle",
        headerName: "Positions",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "56px",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "15px",
              display:"flex",
              justifyContent:"center"
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            {field.value ? (
             <DoneIcon/>
            ) : (
             <Close style={{width:"20px"}}/>
            )}
          </Box>
        ),
      },
      {
        field: "athlete_bikes2",
        headerName: "Bike Name",
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        hideSortIcons: true,
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "15px",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Box>
              <Typography variant="h4">
                {field?.row?.athlete_bikes?.bike_name
                  ? field?.row?.athlete_bikes?.bike_name
                  : "-"}
              </Typography>
            </Box>
          </Box>
        ),
      },
      {
        field: "athlete_bikes",
        headerName: "Bike Type",
        filterable: false,
        disableColumnMenu: true,
        hideSortIcons: true,
        sortable: false,
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "15px",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Typography variant="h4">
              {field?.value?.bike_types ? field?.value?.bike_types?.name : "-"}
            </Typography>
          </Box>
        ),
      },
      {
        field: "average_cadence",
        headerName: "Avg Cadence",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "15px",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Typography variant="h4">
              {field.value ? `${field.value.toFixed(0)} rpm` : "-"}
            </Typography>
          </Box>
        ),
      },
      {
        field: "max_heartrate",
        headerName: "Max HR",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "15px",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Typography variant="h4">
              {!!field.value ? `${field?.value?.toFixed(0)} bpm` : "-"}
            </Typography>
          </Box>
        ),
      },
      {
        field: "max_power",
        headerName: "Max Power",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "15px",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Typography variant="h4">
              {field.value ? `${field.value.toFixed(0)} Watts` : "-"}
            </Typography>
          </Box>
        ),
      },
      {
        field: "elevation_gain",
        headerName: "Elevation",
        flex: 1,
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "15px",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Typography variant="h4">
              {units === "metric"
                ? `${field.value?.toFixed(0) ?? "-"} m `
                : `${
                    convert(field?.value).from("m").to("ft")?.toFixed(0) ?? "-"
                  } ft`}
            </Typography>
          </Box>
        ),
      },

      {
        field: "total_balance",
        headerName: "Total Balance",
        flex: 1,
        valueGetter: (params) => {
          const leftBalance = params.row.average_pedal_balance_left;
          const rightBalance =
            leftBalance === 0 ? 0 : 100 - params.row.average_pedal_balance_left;
          return leftBalance !== null && rightBalance !== null
            ? `${leftBalance?.toFixed(0)} % | ${rightBalance?.toFixed(0)} %`
            : "-";
        },
        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "15px",
            }}
            component={Link}
            to={`/dashboard/session/${field.row.id}/overview`}
          >
            <Typography variant="h4">{field.value}</Typography>
          </Box>
        ),
      },
      {
        field: "undefined",
        headerName: "Actions",
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        hideSortIcons: true,

        renderCell: (field) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              textDecoration: "none",
              color: "inherit",
              pt: "6px",
            }}
          >
            <Stack direction={"row"}>
              <IconButton
                onClick={() => {
                  setOpenDialog(true);
                  setFieldToDelete(field);
                }}
              >
                <img src={DeleteOutlined} alt="delete-icon" width={"15px"} />
              </IconButton>

              <IconButton
                component={Link}
                to={`/dashboard/session/${field.row.id}/analytics`}
              >
                <img src={ActionsOutlined} alt="actions-icon" width={"15px"} />
              </IconButton>
            </Stack>
          </Box>
        ),
      },
      {
        field: "undefined",
        headerName: "Selection",
        filterable: false,
        disableColumnMenu: true,
        hideSortIcons: true,
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
      },
    ],
    [],
  );

  const [columns, setColumns] = useState<Array<GridColDef>>(
    allColumns.filter(
      (col) =>
        col.headerName !== "Bike Type" &&
        col.headerName !== "Avg Cadence" &&
        col.headerName !== "Max HR" &&
        col.headerName !== "Max Power" &&
        col.headerName !== "Elevation" &&
        col.headerName !== "Total Balance",
    ),
  );

  useEffect(() => {
    setColumns(
      customBreakpoint
        ? allColumns.filter(
            (col) =>
              col.headerName !== "Bike Type" &&
              col.headerName !== "Avg Cadence" &&
              col.headerName !== "Max HR" &&
              col.headerName !== "Max Power" &&
              col.headerName !== "Elevation" &&
              col.headerName !== "Total Balance" &&
              col.headerName !== "Avg Speed" &&
              col.headerName !== "Avg Power" &&
              col.headerName !== "Distance",
          )
        : allColumns.filter(
            (col) =>
              col.headerName !== "Bike Type" &&
              col.headerName !== "Bike Name" &&
              col.headerName !== "Avg Cadence" &&
              col.headerName !== "Max HR" &&
              col.headerName !== "Max Power" &&
              col.headerName !== "Elevation" &&
              col.headerName !== "Total Balance",
          ),
    );
  }, [customBreakpoint]);

  const [deletedSessions, setDeletedSessions] = useState<Array<string>>([]);

  // When a user delete's a session append the deleted session id to the deletedSessions state
  useEffect(() => {
    if (isFulfilled(deleteSessionCall)) {
      setDeletedSessions((sessions) =>
        sessions.concat([deleteSessionCall.result]),
      );
      deleteSessionCall.unload();
    }
  }, [deleteSessionCall]);

  const rows = useMemo(
    () =>
      props.sessions?.filter(
        (session) => !deletedSessions.includes(session.id),
      ),
    [props.sessions, deletedSessions],
  );

  const [search, setSearch] = useState("");
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [filters, setFilters] = useState<
    Array<DataGridFilter<number | Date | string>>
  >([]);

  const constructCall: (
    searchInput?: string,
    sortModelInput?: GridSortModel,
  ) => Action<any, any, any> = useCallback(
    (searchInput?, sortModelInput?) => ({
      kind: "MODIFY_CALL",
      pageSize,
      builder: (builder) =>
        withReference(searchInput ?? search, (search) =>
          withReference(
            search !== ""
              ? builder.ilike("recording_name", `%${search}%`)
              : builder,
            (builder) =>
              withReference(sortModelInput ?? sortModel, (sortModel) =>
                withReference(
                  sortModel.length !== 0 && !!sortModel[0].sort
                    ? builder.order(
                        sortModel[0].field === "total_balance"
                          ? "average_pedal_balance_left"
                          : sortModel[0].field,
                        {
                          ascending: sortModel[0].sort === "asc",
                        },
                      )
                    : builder.order("date", { ascending: false }),
                  (builder) =>
                    filters.reduce(
                      (currentBuilder, filter) =>
                        match(filter)
                          .with(
                            {
                              field: "distance",
                              operation: "between",
                              startValue: P.select("startValue"),
                              endValue: P.select("endValue"),
                            },
                            ({ startValue, endValue }) =>
                              currentBuilder
                                .gte(
                                  "total_distance",
                                  units === "metric"
                                    ? startValue
                                    : convert(startValue as number)
                                        .from("mi")
                                        .to("km"),
                                )
                                .lte(
                                  "total_distance",
                                  units === "metric"
                                    ? endValue
                                    : convert(endValue as number)
                                        .from("mi")
                                        .to("km"),
                                ),
                          )
                          .with(
                            {
                              field: "distance",
                              operation: "less-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.lte(
                                "total_distance",
                                units === "metric"
                                  ? value
                                  : convert(value as number)
                                      .from("mi")
                                      .to("km"),
                              ),
                          )
                          .with(
                            {
                              field: "distance",
                              operation: "greater-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.gte(
                                "total_distance",
                                units === "metric"
                                  ? value
                                  : convert(value as number)
                                      .from("mi")
                                      .to("km"),
                              ),
                          )
                          .with(
                            {
                              field: "average_speed",
                              operation: "between",
                              startValue: P.select("startValue"),
                              endValue: P.select("endValue"),
                            },
                            ({ startValue, endValue }) =>
                              currentBuilder
                                .gte(
                                  "average_speed",
                                  units === "metric"
                                    ? startValue
                                    : (startValue as number) * 1.609344,
                                )
                                .lte(
                                  "average_speed",
                                  units === "metric"
                                    ? endValue
                                    : (endValue as number) * 1.609344,
                                ),
                          )
                          .with(
                            {
                              field: "average_speed",
                              operation: "less-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.lte(
                                "average_speed",
                                units === "metric"
                                  ? value
                                  : (value as number) * 1.609344,
                              ),
                          )
                          .with(
                            {
                              field: "average_speed",
                              operation: "greater-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.gte(
                                "average_speed",
                                units === "metric"
                                  ? value
                                  : (value as number) * 1.609344,
                              ),
                          )
                          .with(
                            {
                              field: "average_power",
                              operation: "between",
                              startValue: P.select("startValue"),
                              endValue: P.select("endValue"),
                            },
                            ({ startValue, endValue }) =>
                              currentBuilder
                                .gte("average_power", startValue)
                                .lte("average_power", endValue),
                          )
                          .with(
                            {
                              field: "average_power",
                              operation: "less-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.lte("average_power", value),
                          )
                          .with(
                            {
                              field: "average_power",
                              operation: "greater-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.gte("average_power", value),
                          )
                          .with(
                            {
                              field: "provider",
                              values: P.select("values"),
                            },
                            ({ values }) =>
                              (values as Array<string>).length === 0
                                ? currentBuilder
                                : currentBuilder.in(
                                    "provider",
                                    values as Array<string>,
                                  ),
                          )
                          .with(
                            {
                              field: "ride_types",
                              values: P.select("values"),
                            },
                            ({ values }) =>
                              (values as Array<string>).length === 0
                                ? currentBuilder
                                : currentBuilder.in(
                                    "ride_types.name",
                                    values as Array<string>,
                                  ),
                          )
                          .with(
                            {
                              field: "athlete_bikes",
                              values: P.select("values"),
                            },
                            ({ values }) =>
                              (values as Array<string>).length === 0
                                ? currentBuilder
                                : currentBuilder
                                    .not("athlete_bike_id", "is", null)
                                    .in(
                                      "athlete_bikes.bike_types.name",
                                      values as Array<string>,
                                    ),
                          )
                          .with(
                            {
                              field: "date",
                              operation: "less-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.lte(
                                "date",
                                dayjs(value as Date).format("MM/DD/YYYY"),
                              ),
                          )
                          .with(
                            {
                              field: "date",
                              operation: "greater-than-equal",
                              value: P.select("value"),
                            },
                            ({ value }) =>
                              currentBuilder.gte(
                                "date",
                                dayjs(value as Date).format("MM/DD/YYYY"),
                              ),
                          )
                          .with(
                            {
                              field: "date",
                              operation: "between",
                              startValue: P.select("startValue"),
                              endValue: P.select("endValue"),
                            },
                            ({ startValue, endValue }) =>
                              currentBuilder
                                .gte(
                                  "date",
                                  dayjs(startValue as Date).format(
                                    "MM/DD/YYYY",
                                  ),
                                )
                                .lte(
                                  "date",
                                  dayjs(endValue as Date).format("MM/DD/YYYY"),
                                ),
                          )
                          .otherwise(
                            (_) => (console.log("unmatched", _), builder),
                          ),
                      builder,
                    ),
                ),
              ),
          ),
        ),
    }),
    [search, sortModel, units, filters],
  );

  const triggerFetch = useCallback(
    (searchInput?: string, sortModelInput?: GridSortModel) => {
      props.dispatch(constructCall(searchInput, sortModelInput));
      props.dispatch({ kind: "FETCH" });
      setCurrentPage(0);
    },
    [constructCall],
  );

  const [currentPage, setCurrentPage] = useState(0);

  //Date Popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  //Values Popover

  //Distance

  const [anchorElVal, setAnchorElVal] = useState(null);

  const handlePopoverOpenVal = (event) => {
    setAnchorElVal(event.currentTarget);
  };

  const handlePopoverCloseVal = () => {
    setAnchorElVal(null);
  };

  const openVal = Boolean(anchorElVal);

  //Speed

  const [anchorElValS, setAnchorElValS] = useState(null);

  const handlePopoverOpenValS = (event) => {
    setAnchorElValS(event.currentTarget);
  };

  const handlePopoverCloseValS = () => {
    setAnchorElValS(null);
  };

  const openValS = Boolean(anchorElValS);

  //Power

  const [anchorElValP, setAnchorElValP] = useState(null);

  const handlePopoverOpenValP = (event) => {
    setAnchorElValP(event.currentTarget);
  };

  const handlePopoverCloseValP = () => {
    setAnchorElValP(null);
  };

  const openValP = Boolean(anchorElValP);

  //Ride Type

  const [anchorElSelectT, setAnchorElSelectT] = useState(null);

  const handlePopoverOpenSelectT = (event) => {
    setAnchorElSelectT(event.currentTarget);
  };

  const handlePopoverCloseSelectT = () => {
    setAnchorElSelectT(null);
  };

  const openSelectT = Boolean(anchorElSelectT);

  //Bike Type

  const [anchorElSelectB, setAnchorElSelectB] = useState(null);

  const handlePopoverOpenSelectB = (event) => {
    setAnchorElSelectB(event.currentTarget);
  };

  const handlePopoverCloseSelectB = () => {
    setAnchorElSelectB(null);
  };

  const openSelectB = Boolean(anchorElSelectB);

  // Provider

  const [anchorElSelectP, setAnchorElSelectP] = useState(null);

  const handlePopoverOpenSelectP = (event) => {
    setAnchorElSelectP(event.currentTarget);
  };

  const handlePopoverCloseSelectP = () => {
    setAnchorElSelectP(null);
  };

  const openSelectP = Boolean(anchorElSelectP);

  //Visibility

  const [anchorElMulti, setAnchorElMulti] = useState(null);

  const handlePopoverOpenMulti = (event) => {
    setAnchorElMulti(event.currentTarget);
  };

  const handlePopoverCloseMulti = () => {
    setAnchorElMulti(null);
  };

  const openMulti = Boolean(anchorElMulti);

  //Filters

  useEffect(() => {
    triggerFetch();
  }, [filters]);

  const handleFilterApply = useCallback(
    (filter) => {
      setFilters((filters) =>
        !!filters.find((f) => f.field === filter.field)
          ? filters.map((f) => (f.field === filter.field ? { ...filter } : f))
          : filters.concat([filter]),
      );
    },
    [setFilters],
  );

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleClose = () => {
    setIsFilterOpen(false);
  };

  const [clearTrigger, setClearTrigger] = useState(false);

  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            background: "#232a2e",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
        aria-modal={true}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>
          {"Are you sure you want to delete this session?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By clicking Yes your selected session will be deleted permanently.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(_) => {
              if (isUnloaded(deleteSessionCall)) {
                deleteSessionCall.fire(async () =>
                  deleteSessionPromise(fieldToDelete.row.id),
                );
                setOpenDialog(false);
              }
            }}
          >
            Yes
          </Button>
          <Button onClick={() => setOpenDialog(false)}>No</Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          flexDirection: customBreakpoint ? "column" : "row",
          justifyContent: customBreakpoint ? "center" : "space-between",
          alignItems: customBreakpoint ? "center" : "none",
          marginBottom: "25px",
          gap: customBreakpoint ? "2rem" : "none",
        }}
      >
        {!isMobileScreen && (
          <Box
            sx={{
              width: customBreakpoint ? "100%" : "65%",
              padding: customBreakpoint ? "0.5em" : "none",
              backgroundColor: "#2C262D",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              height: "38px",
            }}
          >
            <Box
              onClick={handlePopoverOpen}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Date</Typography>
              <IconButton>
                <img src={FilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>
            <Box
              onClick={handlePopoverOpenVal}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Distance</Typography>
              <IconButton>
                <img src={FilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>
            <Box
              onClick={handlePopoverOpenValS}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Avg Speed</Typography>
              <IconButton>
                <img src={FilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>
            <Box
              onClick={handlePopoverOpenValP}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Avg Power</Typography>
              <IconButton>
                <img src={FilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>

            <Box
              onClick={handlePopoverOpenSelectT}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Ride Type</Typography>
              <IconButton>
                <img src={FilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>

            <Box
              onClick={handlePopoverOpenSelectB}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Bike Type</Typography>
              <IconButton>
                <img src={FilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>

            <Box
              onClick={handlePopoverOpenSelectP}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography>Provider</Typography>
              <IconButton>
                <img src={FilterDownArrowIcon} alt="filter-down-arrow" />
              </IconButton>
            </Box>

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <NewDateFilterComponent
                onApply={handleFilterApply}
                onClose={handlePopoverClose}
              />
            </Popover>
            <Popover
              open={openVal}
              anchorEl={anchorElVal}
              onClose={handlePopoverCloseVal}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <NewNumericFilterComponent
                metric={units === "metric" ? "km" : "mi"}
                fieldName="distance"
                onApply={handleFilterApply}
                onClose={handlePopoverCloseVal}
              />
            </Popover>
            <Popover
              open={openValS}
              anchorEl={anchorElValS}
              onClose={handlePopoverCloseValS}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <NewNumericFilterComponent
                metric={units === "metric" ? "kph" : "mph"}
                fieldName="average_speed"
                onApply={handleFilterApply}
                onClose={handlePopoverCloseValS}
              />
            </Popover>
            <Popover
              open={openValP}
              anchorEl={anchorElValP}
              onClose={handlePopoverCloseValP}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <NewNumericFilterComponent
                metric="W"
                fieldName="average_power"
                onApply={handleFilterApply}
                onClose={handlePopoverCloseValP}
              />
            </Popover>
            <Popover
              open={openSelectT}
              anchorEl={anchorElSelectT}
              onClose={handlePopoverCloseSelectT}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <MultiSelectFilterComponent
                fieldName="ride_types"
                onApply={handleFilterApply}
                onClose={handlePopoverCloseSelectT}
                options={[
                  { label: "Indoor", value: "Indoor" },
                  { label: "Outdoor", value: "Outdoor" },
                  { label: "Virtual", value: "Virtual" },
                ]}
                clearTrigger={clearTrigger}
              />
            </Popover>
            <Popover
              open={openSelectB}
              anchorEl={anchorElSelectB}
              onClose={handlePopoverCloseSelectB}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <MultiSelectFilterComponent
                fieldName="athlete_bikes"
                onApply={handleFilterApply}
                onClose={handlePopoverCloseSelectB}
                options={[
                  { label: "Road", value: "Road" },
                  { label: "TT", value: "TT" },
                ]}
                clearTrigger={clearTrigger}
              />
            </Popover>
            <Popover
              open={openSelectP}
              anchorEl={anchorElSelectP}
              onClose={handlePopoverCloseSelectP}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
            >
              <MultiSelectFilterComponent
                fieldName="provider"
                onApply={handleFilterApply}
                onClose={handlePopoverCloseSelectP}
                options={[
                  { label: "Darefore", value: "Darefore" },
                  { label: "Garmin", value: "Garmin" },
                  { label: "Strava", value: "Strava" },
                  { label: "Manual", value: "Manual" },
                ]}
                clearTrigger={clearTrigger}
              />
            </Popover>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: customBreakpoint ? "100%" : "58%",
            justifyContent: customBreakpoint ? "center" : "flex-end",
            gap: "1rem",
            flexDirection: isSmallTablet ? "column" : "row",
          }}
        >
          <Box sx={{ display: "flex", gap: "1rem" }}>
            {!isMobileScreen && (
              <Button
                variant="text"
                sx={{ color: "#F7F3F0", border: "1px solid #F7F3F0" }}
                onClick={(_) => (setFilters([]), setClearTrigger((tr) => !tr))}
              >
                Reset Filters
              </Button>
            )}

            <SearchInputWrapper
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon sx={{ opacity: "0.5" }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "15.5rem",
                "& .MuiOutlinedInput-root": {
                  width: isMobileScreen ? "12rem" : "15.5rem",
                },
              }}
              placeholder="Search sessions..."
              onKeyDown={(e) => e.code === "Enter" && triggerFetch()}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>

          <Box sx={{ display: "flex" }}>
            {!isMobileScreen && (
              <Button
                sx={{
                  backgroundColor: "#2C262D",
                  borderColor: "#2C262D",
                }}
                disabled={selectedRows.length === 0}
                onClick={() => setShowModal(true)}
              >
                Compare
              </Button>
            )}
            {showModal && (
              <WithCache
                tables={["default_positions", "custom_positions"]}
                component={(cached) => (
                  <CompareModal
                    ids={selectedRows}
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    defaultPositions={cached.default_positions}
                    customPositions={cached.custom_positions}
                  />
                )}
              />
            )}
            {!isMobileScreen && (
              <IconButton
                sx={{ color: "#BC6D29", p: 1 }}
                onClick={handlePopoverOpenMulti}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            )}
          </Box>
          <Popover
            open={openMulti}
            anchorEl={anchorElMulti}
            onClose={handlePopoverCloseMulti}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <ShowHideColumnsComponent
              allColumns={allColumns
                .filter(
                  (col) =>
                    col.headerName !== "Actions" &&
                    col.headerName !== "Selection",
                )
                .map((col) => col.headerName)}
              defaultSelectedColumns={columns.map((col) => col.headerName)}
              onChange={(cols) =>
                setColumns(
                  allColumns.filter(
                    (col) =>
                      cols.includes(col.headerName) ||
                      col.headerName === "Actions" ||
                      col.headerName === "Selection",
                  ),
                )
              }
            />
          </Popover>
        </Box>
      </Box>
      <CustomDataGrid
        disableColumnMenu
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        sortingMode="server"
        filterMode="server"
        onSortModelChange={(model) => {
          if (model?.[0]?.field !== "undefined") {
            setSortModel(model);
            triggerFetch(search, model);
          }
        }}
        rowCount={props.count}
        pageSize={pageSize}
        page={currentPage}
        onPageChange={(newPage) => {
          if (newPage > currentPage) {
            props.dispatch({ kind: "NEXT_PAGE" });
          } else {
            props.dispatch({ kind: "PREV_PAGE" });
          }
          setCurrentPage(newPage);
        }}
        onPageSizeChange={(newPageSize) => {
          props.dispatch({ kind: "CHANGE_PAGE_SIZE", pageSize: newPageSize });
          setPageSize(newPageSize);
          setCurrentPage(0);
        }}
        rowsPerPageOptions={[10, 20, 50]}
        paginationMode="server"
        autoHeight
        checkboxSelection={!isMobileScreen}
        keepNonExistentRowsSelected
        columnTypes={{
          [GRID_CHECKBOX_SELECTION_COL_DEF.type]:
            GRID_CHECKBOX_SELECTION_COL_DEF,
        }}
        onSelectionModelChange={(selectionModel, details) => {
          if (selectionModel.length < 5) setSelectedRows(selectionModel);
        }}
        selectionModel={selectedRows}
        // isRowSelectable={(params) => {
        //   return selectedRows.length === 4 ? false : true;
        // }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        components={{
          ColumnSortedAscendingIcon: ExpandLessIcon,
          ColumnSortedDescendingIcon: ExpandMoreIcon,
        }}
        columnVisibilityModel={{
          provider: !isMobileScreen,
          total_distance: !isMobileScreen,
          total_time: !isMobileScreen,
          ride_types: !isMobileScreen,
          athlete_bikes2: !isMobileScreen,
          undefined: !isMobileScreen,
        }}
        sx={{
          "& .MuiDataGrid-cell[data-field='undefined']": {
            padding: 0,
          },
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "none",
            },
        }}
      />
    </>
  );
}
