import { Box } from "@mui/material";

import {
	useFilteredMetrics,
	useRideSession,
} from "../customPages/SessionViewsPreload";
import type { TableRow } from "src/contexts/CacheContext";
import AnalyticsTable from "../customComponents/AnalyticsTable";

export type LapsViewProps = {
	defaultPositions: Array<TableRow<"default_positions">>;
	customPositions: Array<TableRow<"custom_positions">>;
};

export default function LapsView(props: LapsViewProps) {
	const session = useRideSession();
	const metrics = useFilteredMetrics();

	return (
		<>
			<Box
				sx={{
					width: "98%",
					background: "#201B20",
					borderRadius: "6px",
					display: "flex",
					flexDirection: { xs: "column", md: "row" },
				}}
			>
				{session?.laps?.length > 0 && metrics?.length > 0 && (
					<AnalyticsTable
						positions={
							metrics[0]?.default_position_id !== null
								? props.defaultPositions
								: props.customPositions
						}
					/>
				)}
			</Box>
		</>
	);
}
