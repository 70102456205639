import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import arrowBack from "../../../../assets/img/layout/coach/MyAthletes/arrowBack.svg";
import cyclistSession from "../../../../assets/img/views/calendar/cyclistIconSession.svg";
import calendarCyclist from "../../../../assets/img/layout/coach/Library/Plans/calendarcyclist.svg";
import PlanCover1 from "../../../../assets/img/layout/coach/Library/Plans/PlanCover1.svg";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import "../../../../assets/scss/DatePicker.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import dayjs from "dayjs";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { sensorList, toHHMMSS, weekdays } from "src/utils/common";
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";

const populateSteps = (steps, start_at) => {
  if (!steps || steps.length === 0) return new Array(7).fill(undefined);
  const lastDate = dayjs(steps[steps.length - 1].date);
  const startDate = dayjs(start_at);
  const lastDay = lastDate.diff(startDate, "day") || 1;
  const numberOfWeeks = Math.ceil(lastDay / 7);
  const emptySteps = new Array(numberOfWeeks * 7).fill(undefined);
  steps.forEach(
    (step) => (emptySteps[dayjs(step.date).diff(startDate, "day")] = step),
  );
  return emptySteps;
};
export default function AssignedTrainingPlanView({ assignedTrainingPlan }) {
  const units = useUnits();
  const navigate = useNavigate();
  const [selectedWeek, setSelectedWeek] = useState(0);
  const isTabletScreen = useMediaQuery("(max-width:1160px)");
  const isMobileScreen = useMediaQuery("(max-width:550px)");

  const steps = useMemo(
    () =>
      populateSteps(
        assignedTrainingPlan.assigned_workout,
        assignedTrainingPlan.start_at,
      ),
    [assignedTrainingPlan.id],
  );
  const numberOfWeeks = useMemo(() => steps.length / 7, [steps]);
  // Tabs Logic
  const handleChange = useCallback((event, newValue) => {
    setSelectedWeek(newValue);
  }, []);
  useEffect(() => {
    setSelectedWeek(steps.length / 7 - 1);
  }, [numberOfWeeks]);
  const chartOptions: ApexOptions = {
    chart: {
      type: "bar",
      height: "100%",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "100%",
      },
    },
    grid: {
      show: false,
    },
    colors: ["#DD4F4A"],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        style: {
          colors: "#C1C1C1",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return value.toFixed(1);
        },
        style: {
          colors: "#C1C1C1",
        },
      },
    },
    title: {
      text: "Training Hours",
      style: {
        color: "#C1C1C1",
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `<div style="text-align: center; font-size:16px; color: red; padding: 5px; border-radius: 5px;">${series[seriesIndex][dataPointIndex]}</div>`;
      },
    },
  };
  const chartSeries: ApexAxisChartSeries = useMemo(() => {
    if (numberOfWeeks < 1) return [{ data: [] }];
    return [
      {
        data: [...Array(numberOfWeeks)].map((_, i) => ({
          y: (
            steps
              .slice(i * 7, i * 7 + 7)
              .reduce((acc, curr) => acc + (curr?.duration || 0), 0) / 3600
          ).toFixed(2),
          x: `Week ${i + 1}`,
        })),
      },
    ];
  }, [numberOfWeeks, steps]);

  const totalTime = useMemo(
    () => steps.reduce((acc, curr) => acc + (curr?.duration || 0), 0),
    [steps],
  );
  const longestStep = useMemo(
    () => steps.reduce((acc, curr) => Math.max(acc, curr?.duration || 0), 0),
    [steps],
  );
  const numberOfSessions = useMemo(
    () => steps.filter((step) => step !== undefined).length,
    [steps],
  );
  const totalTss = useMemo(() => {
    let accTss = 0;
    for (let i = 0; i < steps.length; i++) {
      if (!steps[i]) continue;
      accTss += steps[i].tss || 0;
    }
    return accTss;
  }, [steps]);

  return (
    <>
      <Box
        sx={{
          width: "96%",
          padding: location.pathname.startsWith("/coach") ? "2em" : "0px",
          background: "#201B20",
          borderRadius: ".625rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <IconButton sx={{ padding: ".125rem" }} onClick={() => navigate(-1)}>
            <img src={arrowBack} />
          </IconButton>
          <Typography>Back</Typography>
        </Box>
        <Box height={30} />
        {!isMobileScreen ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingX: "1.875rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                paddingX: "0.5rem",
              }}
            >
              <Typography variant="h2">{assignedTrainingPlan.name}</Typography>
            </Box>
            <Box sx={{ display: "flex", marginTop: "20px" }}>
              {/* Left Side */}
              <Box sx={{ width: "65%" }}>
                <Typography
                  sx={{
                    fontSize: ".9375rem",
                    fontWeight: 700,
                    paddingLeft: "8px",
                    marginBottom: "16px",
                  }}
                >
                  Plan Description
                </Typography>
                <Typography sx={{ paddingLeft: "8px" }} color={"#BDBCBE"}>
                  {assignedTrainingPlan.description}
                </Typography>
                {isTabletScreen && (
                  <>
                    <Typography
                      sx={{
                        fontSize: ".9375rem",
                        fontWeight: 700,
                        paddingLeft: ".5rem",
                        marginTop: "1rem",
                      }}
                    >
                      Cover Image
                    </Typography>
                    <Box
                      sx={{
                        width: "95%",
                        height: "200px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingLeft: ".5rem",
                      }}
                    >
                      {assignedTrainingPlan.image === "" ? (
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          src={PlanCover1}
                        />
                      ) : (
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          src={`${process.env.REACT_APP_SUPABASE_DOMAIN}/storage/v1/object/public/training-plan-images/${assignedTrainingPlan.image}`}
                        />
                      )}
                    </Box>
                    <Divider
                      sx={{
                        width: "95%",
                        border: "1px solid #64605D",
                        marginBottom: "2.2rem",
                      }}
                    />
                  </>
                )}
                <Typography
                  sx={{
                    marginBottom: "1rem",
                    marginTop: "2rem",
                    paddingX: "0.5rem",
                    fontSize: ".9375rem",
                    fontWeight: 700,
                  }}
                >
                  Training Plan Sample
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#2C262D",
                    borderRadius: "6px",
                    paddingBottom: "30px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        paddingX: "1em",
                      }}
                    >
                      <Tabs
                        value={selectedWeek}
                        onChange={handleChange}
                        variant="scrollable"
                        sx={{
                          "& .MuiTab-root:not(.Mui-selected)": {
                            opacity: 0.5,
                          },
                          minHeight: "1.5rem",
                          "& .Mui-selected": {
                            color: "#DD4F4A !important",
                          },
                          width: "100%",
                          marginBottom: "1rem",
                        }}
                        TabIndicatorProps={{
                          sx: {
                            backgroundColor: "#DD4F4A",
                          },
                        }}
                      >
                        {[...Array(numberOfWeeks)].map((tab, index) => (
                          <Tab
                            label={`Week ${index + 1}`}
                            key={index}
                            sx={{ width: "20%" }}
                          />
                        ))}
                      </Tabs>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {steps
                                ?.slice(selectedWeek * 7, selectedWeek * 7 + 7)
                                .map((step, i) => {
                                  if (!step) {
                                    return (
                                      <Box
                                        key={i}
                                        sx={{
                                          width: "calc(100%/7)",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color: "#64605D",
                                            marginTop: "1rem",
                                            textAlign: "center",
                                          }}
                                        >
                                          {weekdays[i]}
                                        </Typography>
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            marginTop: "1.5rem",
                                          }}
                                        >
                                          <img src={calendarCyclist} />
                                          {/* <img src={noData} width={"20px"} /> */}
                                        </Box>
                                      </Box>
                                    );
                                  }
                                  return (
                                    <Box
                                      key={i}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        width: "calc(100%/7)",
                                      }}
                                    >
                                      {!isTabletScreen && (
                                        <Box
                                          sx={{
                                            width: "100%",
                                            textAlign: "center",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            color: "#E3DFDC",
                                            fontFamily: "DejaVu Sans",
                                            fontSize: "10px",
                                          }}
                                        >
                                          {step.name}
                                        </Box>
                                      )}

                                      <Typography
                                        sx={{
                                          color: "#64605D",
                                          marginTop: isTabletScreen
                                            ? "1rem"
                                            : "",
                                        }}
                                      >
                                        {weekdays[i]}
                                      </Typography>
                                      <Box>
                                        {isTabletScreen ? (
                                          <Tooltip
                                            title={
                                              <Box>
                                                <Typography>
                                                  {step.name}
                                                </Typography>
                                                <Typography>
                                                  {toHHMMSS(step.duration)}
                                                </Typography>
                                              </Box>
                                            }
                                            arrow
                                          >
                                            <img
                                              width={22}
                                              style={{
                                                marginTop: "1.5rem",
                                                cursor: "pointer",
                                              }}
                                              src={cyclistSession}
                                              alt="Cyclist Session"
                                            />
                                          </Tooltip>
                                        ) : (
                                          <img
                                            width={22}
                                            style={{ marginTop: "1.5rem" }}
                                            src={cyclistSession}
                                            alt="Cyclist Session"
                                          />
                                        )}
                                      </Box>

                                      {!isTabletScreen && (
                                        <>
                                          <Box sx={{ marginTop: "14px" }}>
                                            {toHHMMSS(step.duration)}
                                          </Box>
                                          <Box>
                                            {units === "metric"
                                              ? `${
                                                  step.distance.toFixed(2) ??
                                                  "0"
                                                } km`
                                              : `${
                                                  step.distance
                                                    ? convert(step.distance)
                                                        .from("km")
                                                        .to("mi")
                                                        .toFixed(2)
                                                    : "0"
                                                } mi`}
                                          </Box>
                                          <Box>TSS®</Box>
                                          <Box>{step.tss}</Box>
                                        </>
                                      )}
                                    </Box>
                                  );
                                })}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        background: "#24211F",
                        borderRadius: "8px",
                        padding: "2em",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        width: "90%",
                      }}
                    >
                      <Typography variant="h4" sx={{ color: "#DD4F4A" }}>
                        {toHHMMSS(totalTime)}
                      </Typography>
                      <Typography variant="h4" sx={{ color: "#E28E54" }}>
                        {totalTss} TSS
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    marginBottom: "1rem",
                    marginTop: "2rem",
                    paddingX: "0.5rem",
                    fontSize: ".9375rem",
                    fontWeight: 700,
                  }}
                >
                  Stats
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#2C262D",
                    height: isTabletScreen ? "380px" : "350px",
                    borderRadius: "6px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      padding: "2em",
                    }}
                  >
                    <Typography variant="h5">
                      AVERAGE WEEKLY BREAKDOWN
                    </Typography>
                    <Grid container>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography
                          variant={isTabletScreen ? "subtitle1" : "h4"}
                        >
                          Workouts Per Week
                        </Typography>
                        <Typography variant="h4" color={"#DD4F4A"}>
                          {(numberOfSessions / numberOfWeeks).toFixed(1)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography
                          variant={isTabletScreen ? "subtitle1" : "h4"}
                        >
                          Weekly Avg.
                        </Typography>
                        <Typography variant="h4" color="#DD4F4A">
                          {toHHMMSS(totalTime / numberOfWeeks)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <Typography
                          variant={isTabletScreen ? "subtitle1" : "h4"}
                        >
                          Longest Workout
                        </Typography>
                        <Typography variant="h4" color="#DD4F4A">
                          {toHHMMSS(longestStep)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box sx={{ height: isTabletScreen ? "180px" : "200px" }}>
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="bar"
                        height={"100%"}
                      />
                    </Box>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    marginBottom: "1rem",
                    marginTop: "2rem",
                    paddingX: "0.5rem",
                    fontSize: ".9375rem",
                    fontWeight: 700,
                  }}
                >
                  Suggested Devices
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "#2C262D",
                    padding: "2em",
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  {sensorList.map((sensor, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        alignItems: "center",
                        textAlign: isTabletScreen ? "center" : "",
                      }}
                    >
                      <img
                        src={sensor.icon}
                        width={"50px"}
                        style={{
                          opacity: assignedTrainingPlan.sensors?.includes(
                            sensor.id,
                          )
                            ? 1
                            : 0.2,
                        }}
                      />
                      <Typography
                        variant="body2"
                        style={{
                          opacity: assignedTrainingPlan.sensors?.includes(
                            sensor.id,
                          )
                            ? 1
                            : 0.2,
                        }}
                      >
                        {sensor.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

              {/* Right Side */}
              <Box sx={{ width: "35%", paddingLeft: "3rem" }}>
                {!isTabletScreen && (
                  <>
                    <Typography variant="h4" marginBottom={"0.5rem"}>
                      Cover Image
                    </Typography>
                    <Box
                      sx={{
                        width: "95%",
                        height: "200px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {assignedTrainingPlan.image === "" ? (
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          src={PlanCover1}
                        />
                      ) : (
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          src={`${process.env.REACT_APP_SUPABASE_DOMAIN}/storage/v1/object/public/training-plan-images/${assignedTrainingPlan.image}`}
                        />
                      )}
                    </Box>
                    <Divider
                      sx={{
                        width: "95%",
                        border: "1px solid #64605D",
                        marginBottom: "2.2rem",
                      }}
                    />
                  </>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginBottom: "1rem",
                  }}
                >
                  <Typography variant="h4">Ride Type</Typography>
                  <Typography variant="h2" color={"#64605D"}>
                    {assignedTrainingPlan.ride_type}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginBottom: "1rem",
                  }}
                >
                  <Typography variant="h4">Difficulty</Typography>
                  <Typography variant="h2" color={"#64605D"}>
                    {assignedTrainingPlan.difficulty}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginBottom: "1rem",
                  }}
                >
                  <Typography variant="h4">Length</Typography>
                  <Typography variant="h2" color={"#64605D"}>
                    {numberOfWeeks} {numberOfWeeks === 1 ? "Week" : "Weeks"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    marginBottom: "1rem",
                  }}
                >
                  <Typography variant="h4">Number of sessions</Typography>
                  <Typography variant="h2" color={"#64605D"}>
                    {numberOfSessions}
                  </Typography>
                </Box>
                {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  marginBottom: "1rem",
                }}
              >
                <Typography variant="h4">Price</Typography>
                <Typography variant="h2" color={"#64605D"}>€ {assignedTrainingPlan.price || 0}</Typography>
              </Box> */}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h2">{assignedTrainingPlan.name}</Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  marginBottom: "0.5rem",
                }}
              >
                <Typography variant="h4">Difficulty :</Typography>
                <Typography variant="h4" color={"#B26828"}>
                  {assignedTrainingPlan.difficulty}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  marginBottom: "0.5rem",
                }}
              >
                <Typography variant="h4">Ride Type :</Typography>
                <Typography variant="h4" color={"#B26828"}>
                  {assignedTrainingPlan.ride_type}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  marginBottom: "0.5rem",
                }}
              >
                <Typography variant="h4">Length :</Typography>
                <Typography variant="h4" color={"#B26828"}>
                  {numberOfWeeks} {numberOfWeeks === 1 ? "Week" : "Weeks"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  marginBottom: "1rem",
                }}
              >
                <Typography variant="h4">Number of sessions :</Typography>
                <Typography variant="h4" color={"#B26828"}>
                  {numberOfSessions}
                </Typography>
              </Box>
            </Box>
            <Typography variant="h3">Plan Description</Typography>
            <Typography color={"#BDBCBE"}>
              {assignedTrainingPlan.description}
            </Typography>
            <Box>
              <Typography
                sx={{
                  marginBottom: "1rem",
                  marginTop: "2rem",
                  paddingX: "0.5rem",
                  fontSize: ".9375rem",
                  fontWeight: 700,
                }}
              >
                Training Plan Sample
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#2C262D",
                  borderRadius: "6px",
                  paddingBottom: "30px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      paddingX: "0.5em",
                    }}
                  >
                    <Tabs
                      value={selectedWeek}
                      onChange={handleChange}
                      variant="scrollable"
                      sx={{
                        "& .MuiTab-root:not(.Mui-selected)": {
                          opacity: 0.5,
                        },
                        minHeight: "1.5rem",
                        "& .Mui-selected": {
                          color: "#DD4F4A !important",
                        },
                        width: "100%",
                        marginBottom: "1rem",
                      }}
                      TabIndicatorProps={{
                        sx: {
                          backgroundColor: "#DD4F4A",
                        },
                      }}
                    >
                      {[...Array(numberOfWeeks)].map((tab, index) => (
                        <Tab
                          label={`Week ${index + 1}`}
                          key={index}
                          sx={{ width: "20%" }}
                        />
                      ))}
                    </Tabs>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {steps
                              ?.slice(selectedWeek * 7, selectedWeek * 7 + 7)
                              .map((step, i) => {
                                if (!step) {
                                  return (
                                    <Box
                                      key={i}
                                      sx={{
                                        width: "calc(100%/7)",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#64605D",
                                          marginTop: "1rem",
                                          textAlign: "center",
                                        }}
                                      >
                                        {weekdays[i]}
                                      </Typography>
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          flexDirection: "column",
                                          marginTop: "1.5rem",
                                        }}
                                      >
                                        <img src={calendarCyclist} />
                                      </Box>
                                    </Box>
                                  );
                                }
                                return (
                                  <Box
                                    key={i}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      width: "calc(100%/7)",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: "#64605D",
                                        marginTop: "1rem",
                                      }}
                                    >
                                      {weekdays[i]}
                                    </Typography>
                                    <Box>
                                      {isTabletScreen ? (
                                        <Tooltip
                                          title={
                                            <Box>
                                              <Typography>
                                                {step.name}
                                              </Typography>
                                              <Typography>
                                                {toHHMMSS(step.duration)}
                                              </Typography>
                                            </Box>
                                          }
                                          arrow
                                        >
                                          <img
                                            width={22}
                                            style={{
                                              marginTop: "1.5rem",
                                              cursor: "pointer",
                                            }}
                                            src={cyclistSession}
                                            alt="Cyclist Session"
                                          />
                                        </Tooltip>
                                      ) : (
                                        <img
                                          width={22}
                                          style={{ marginTop: "1.5rem" }}
                                          src={cyclistSession}
                                          alt="Cyclist Session"
                                        />
                                      )}
                                    </Box>

                                    {!isTabletScreen && (
                                      <>
                                        <Box sx={{ marginTop: "14px" }}>
                                          {toHHMMSS(step.duration)}
                                        </Box>
                                        <Box>
                                          {units === "metric"
                                            ? `${
                                                step.distance.toFixed(2) ?? "0"
                                              } km`
                                            : `${
                                                step.distance
                                                  ? convert(step.distance)
                                                      .from("km")
                                                      .to("mi")
                                                      .toFixed(2)
                                                  : "0"
                                              } mi`}
                                        </Box>
                                        <Box>TSS®</Box>
                                        <Box>{step.tss}</Box>
                                      </>
                                    )}
                                  </Box>
                                );
                              })}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      background: "#24211F",
                      borderRadius: "8px",
                      padding: "2em",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      width: "90%",
                    }}
                  >
                    <Typography variant="h4" sx={{ color: "#DD4F4A" }}>
                      {toHHMMSS(totalTime)}
                    </Typography>
                    <Typography variant="h4" sx={{ color: "#E28E54" }}>
                      {totalTss} TSS
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Typography
                sx={{
                  marginBottom: "1rem",
                  marginTop: "2rem",
                  paddingX: "0.5rem",
                  fontSize: ".9375rem",
                  fontWeight: 700,
                }}
              >
                Stats
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#2C262D",
                  height: "430px",
                  borderRadius: "6px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    padding: "2em",
                  }}
                >
                  <Typography variant="h5">AVERAGE WEEKLY BREAKDOWN</Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant={isTabletScreen ? "subtitle1" : "h4"}>
                        Workouts Per Week
                      </Typography>
                      <Typography variant="h4" color={"#DD4F4A"}>
                        {(numberOfSessions / numberOfWeeks).toFixed(1)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant={isTabletScreen ? "subtitle1" : "h4"}>
                        Weekly Avg.
                      </Typography>
                      <Typography variant="h4" color="#DD4F4A">
                        {toHHMMSS(totalTime / numberOfWeeks)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Typography variant={isTabletScreen ? "subtitle1" : "h4"}>
                        Longest Workout
                      </Typography>
                      <Typography variant="h4" color="#DD4F4A">
                        {toHHMMSS(longestStep)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box sx={{ height: "200px" }}>
                    <Chart
                      options={chartOptions}
                      series={chartSeries}
                      type="bar"
                      height={"100%"}
                    />
                  </Box>
                </Box>
              </Box>
              <Typography
                sx={{
                  marginBottom: "1rem",
                  marginTop: "2rem",
                  paddingX: "0.5rem",
                  fontSize: ".9375rem",
                  fontWeight: 700,
                }}
              >
                Suggested Devices
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#2C262D",
                  padding: "2em",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {sensorList.map((sensor, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      src={sensor.icon}
                      width={"35px"}
                      style={{
                        opacity: assignedTrainingPlan.sensors?.includes(
                          sensor.id,
                        )
                          ? 1
                          : 0.2,
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{
                        opacity: assignedTrainingPlan.sensors?.includes(
                          sensor.id,
                        )
                          ? 1
                          : 0.2,
                      }}
                    >
                      {sensor.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
