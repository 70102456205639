import { Box, Popover, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useEffect, useMemo } from "react";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import type { TableRow } from "src/contexts/CacheContext";
import { useNavigate } from "react-router-dom";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import type { Json } from "src/utils/DatabaseDefinitions";
import MedalIcon from "../../../assets/img/views/notifications/medal.svg";
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

export type NotificationPopoverProps = {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  notifications: Array<TableRow<"notifications">>;
};

export default function NotificationPopover(props: NotificationPopoverProps) {
  const supabase = useSupabase();
  const today = dayjs().startOf("day").utc(true);
  const yesterdayStart = today.subtract(1, "day");
  const yesterdayEnd = yesterdayStart.endOf("day");

  const orderedNotifications = useMemo(() => {
    return props.notifications.sort((a, b) =>
      new Date(a.created_at) <= new Date(b.created_at) ? 1 : -1,
    );
  }, [props.notifications]);

  const readNotifications = useMemo(() => {
    return props.notifications.filter((noti) => noti.read === false);
  }, [props.notifications]);

  const readNotificationsPromise = useCallback(
    (
      values: {
        content: Json;
        created_at: string;
        dismissable: boolean;
        id: number;
        read: boolean;
        type: string;
        user_id: string;
      }[],
    ) => supabase.from("notifications").upsert(values).throwOnError(),
    [supabase],
  );

  const readNotificationsCall =
    useAsyncState<SupabaseCall<typeof readNotificationsPromise>>();

  useEffect(() => {
    if (props.anchorEl !== null) {
      const values = readNotifications.flatMap((noti, index) => {
        return {
          id: noti.id,
          content: noti.content,
          created_at: noti.created_at,
          dismissable: noti.dismissable,
          type: noti.type,
          user_id: noti.user_id,
          read: true,
        };
      });
      if ("fire" in readNotificationsCall) {
        readNotificationsCall.fire(async () =>
          readNotificationsPromise(values),
        );
      }
    }
  }, [props.anchorEl]);

  //Delete
  const deletePromise = useCallback(
    (notificationId: number) =>
      supabase
        .from("notifications")
        .delete()
        .eq("id", notificationId)
        .throwOnError(),
    [supabase],
  );

  const deleteCall = useAsyncState<SupabaseCall<typeof deletePromise>>();

  const navigate = useNavigate();

  return (
    <>
      <Popover
        open={Boolean(props.anchorEl)}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            backgroundImage: "none",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "10px",
            maxHeight: "320px",
          }}
        >
          {orderedNotifications.length > 0 ? (
            orderedNotifications.map((notification, index) => (
              <Box
                key={index}
                sx={{
                  paddingX: 1.5,
                  pt: 1.5,
                  width: "350px",
                  overflowY: "auto",
                  display: "flex",
                  justifyContent: "space-between",
                  "&:hover": {
                    ".manual-icon": {
                      display: "none",
                    },
                    color: "#BC6D2A",
                  },
                  borderBottom:
                    index !== orderedNotifications.length - 1
                      ? "1px solid #64605D"
                      : "none",
                }}
              >
                <Box
                  mb={1}
                  onClick={
                    notification.type === "Personal Record" ||
                    notification.type === "Position Record"
                      ? () => navigate("/dashboard/personal-records")
                      : !!notification.content["session_id"]
                      ? () => {
                          navigate(
                            `/dashboard/session/${notification.content["session_id"]}/overview`,
                          );
                          navigate(0);
                        }
                      : notification.content["description"].includes(
                          "Your account has been downgraded to Basic",
                        )
                      ? () => navigate("/dashboard/settings/subscription")
                      : notification.type === "Connection Request"
                      ? () => navigate("/dashboard/settings/my_coach")
                      : (_) => _
                  }
                  sx={{
                    "&:hover": {
                      cursor:
                        !!notification.content["session_id"] ||
                        notification.content["description"].includes(
                          "Your account has been downgraded to Basic",
                        )
                          ? "pointer"
                          : "default",
                    },
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    {notification.type === "New Session" ? (
                      <TaskOutlinedIcon
                        style={{ alignSelf: "center" }}
                        fontSize="large"
                      />
                    ) : notification.type === "Personal Record" ? (
                      <EmojiEventsOutlinedIcon
                        style={{ alignSelf: "center" }}
                        fontSize="large"
                      />
                    ) : notification.type === "Position Record" ? (
                      <DirectionsBikeIcon
                        style={{ alignSelf: "center" }}
                        fontSize="large"
                      />
                    ) : notification.type === "New Medal" ? (
                      <img
                        src={MedalIcon}
                        alt={"Medal"}
                        width={"35px"}
                        height={"35px"}
                        style={{ alignSelf: "center" }}
                      />
                    ) : (
                      // Next type etc
                      <InfoOutlinedIcon
                        style={{ alignSelf: "center" }}
                        fontSize="large"
                      />
                    )}
                    <Box>
                      <Typography>{notification.type}</Typography>
                      <Typography variant="body2">
                        {notification.content["description"]}
                      </Typography>
                      <Box
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        display={"flex"}
                      >
                        <Typography variant="subtitle2" sx={{ opacity: 0.5 }}>
                          {dayjs(notification.created_at).isSameOrAfter(
                            today,
                            "day",
                          )
                            ? `Today at ${dayjs(notification.created_at).format(
                                "HH:mm",
                              )}`
                            : dayjs(notification.created_at).isBetween(
                                yesterdayStart,
                                yesterdayEnd,
                                "hour",
                              )
                            ? `Yesterday at ${dayjs(
                                notification.created_at,
                              ).format("HH:mm")}`
                            : `${dayjs(notification.created_at).format(
                                "dddd DD/MM/YYYY HH:mm",
                              )}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Stack>

                  {/* {index !== notificationsCall.result.length - 1 && <Divider />} */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {notification.dismissable && (
                    <IconButton
                      size="small"
                      sx={{ padding: 0, mb: "3px" }}
                      onClick={() => {
                        if ("fire" in deleteCall) {
                          deleteCall.fire(async () =>
                            deletePromise(notification.id).then((r) => {
                              return r;
                            }),
                          );
                        }
                      }}
                    >
                      <CloseIcon sx={{ width: "12px" }} />
                    </IconButton>
                  )}
                  {/* <Avatar
                    className="manual-icon"
                    onMouseEnter={() => setIsHovered(index)}
                    onMouseLeave={() => setIsHovered(null)}
                    sx={{
                      width: "8px",
                      height: "8px",
                      background: "#BC6D29",
                      display: isHovered === index ? "none" : "block",
                    }}
                  >
                    <FiberManualRecordIcon
                      sx={{ fontSize: "8px", color: "#BC6D29" }}
                    />
                  </Avatar> */}
                </Box>
              </Box>
            ))
          ) : (
            <>
              <Box
                sx={{
                  padding: 1.5,
                  width: "230px",
                  overflowY: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#726174",
                }}
              >
                <Typography>No Notifications</Typography>
              </Box>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
}
