import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import ArrowForwardTwoToneIcon from "@mui/icons-material/ArrowForwardTwoTone";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import type { ApexOptions } from "apexcharts";
import ApexCharts from "apexcharts";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"; // import plugin
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"; // import plugin
import { useCallback, useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import {
  allBikePositions,
  allColors,
  BikePosition,
  roadBikePositions,
  ttBikePositions,
  withReference,
} from "src/utils/common";
import PreloadComponent from "src/utils/PreloadComponent";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
import IndoorIcon from "../../../assets/img/views/dashboard-progress/indoor-active.svg";
import IndoorInIcon from "../../../assets/img/views/dashboard-progress/indoor-inactive.svg";
import OutdoorIcon from "../../../assets/img/views/dashboard-progress/outdoor-inactive.svg";
import OutdoorActIcon from "../../../assets/img/views/dashboard-progress/outdoor-active.svg";
import IdealIcon from "../../../assets/img/views/dashboard-progress/ideal-line.svg";
import NotIdealIcon from "../../../assets/img/views/dashboard-progress/notideal-line.svg";
import { TableRow, WithCache } from "src/contexts/CacheContext";

type Month =
  | "Jan"
  | "Feb"
  | "Mar"
  | "Apr"
  | "May"
  | "Jun"
  | "Jul"
  | "Aug"
  | "Sep"
  | "Oct"
  | "Nov"
  | "Dec";

type Day = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";

const days: Array<Day> = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const months: Array<Month> = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

type Week = { [K in Day]: { [P in BikePosition]: number } };

export type CoachPositionsSessionsProps = {
  sessions: {
    date: string;
    session_statistics: {
      cadence_max: number;
      created_at: string;
      default_position_id: number;
      custom_position_id: number;
      heart_rate_average: number;
      heart_rate_max: number;
      id: number;
      pedal_balance_left: number;
      pedal_balance_right: number;
      position_percentage: number;
      power_average: number;
      power_max: number;
      session_id: string;
      slope_average: number;
      slope_negative_percentage: number;
      slope_positive_percentage: number;
      speed_average: number;
      speed_max: number;
    }[];
    ride_types: { name: string };
    athlete_bikes: { bike_types: { name: string } };
  }[];
  bicycleType: "TT" | "Road";
  defaultPositions: Array<TableRow<"default_positions">>;
  customPositions: Array<TableRow<"custom_positions">>;
};

export type PreloadProps = {
  userId: string;
};

export default function CoachPositionsCharts(props: PreloadProps) {
  return (
    <>
      <PreloadComponent<{
        session: {
          date: string;
          session_statistics: {
            cadence_max: number;
            created_at: string;
            default_position_id: number;
            custom_position_id: number;
            heart_rate_average: number;
            heart_rate_max: number;
            id: number;
            pedal_balance_left: number;
            pedal_balance_right: number;
            position_percentage: number;
            power_average: number;
            power_max: number;
            session_id: string;
            slope_average: number;
            slope_negative_percentage: number;
            slope_positive_percentage: number;
            speed_average: number;
            speed_max: number;
          }[];
          ride_types: { name: string };
          athlete_bikes: { bike_types: { name: string } };
        }[];
        customPositions: Array<TableRow<"custom_positions">>;

      }>
      promises={{
        session: async (supabase) =>
          supabase
            .from("session")
            .select(
              "date,session_statistics(*),ride_types(name),athlete_bikes(bike_types(name))",
            )
            .eq("athlete_id", props.userId)
            .order("date", { ascending: true })
            .then((res) => res.data) as any,
        customPositions: (supabase) =>
          supabase
            .from("athlete_bikes")
            .select("id")
            .eq("athlete_id", props.userId)
            .then((athleteBikesRes) =>
              supabase
                .from("custom_positions")
                .select("*")
                .in(
                  "bike_id",
                  athleteBikesRes.data.map((row) => row.id),
                )
                .throwOnError()
                .then((res) => res.data),
            ),
      }}
        component={(props) => (
          <WithCache
            tables={["default_positions"]}
            component={(cached) => (
              <>
                <CoachPositionsSessionsTable
                  sessions={props.session}
                  bicycleType="Road"
                  defaultPositions={cached.default_positions}
                  customPositions={props.customPositions}
                />
                <CoachPositionsSessionsTable
                  sessions={props.session}
                  bicycleType="TT"
                  defaultPositions={cached.default_positions}
                  customPositions={props.customPositions}

                />
              </>
            )}
          />
        )}
      />
    </>
  );
}

function CoachPositionsSessionsTable(props: CoachPositionsSessionsProps) {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState<"Outdoor" | "Indoor">("Indoor");
  const [currentPeriod, setCurrentPeriod] = useState<
    "7 Days" | "4 Weeks" | "6 Months" | "1 Year"
  >("1 Year");

  const [rangeHigh, setRangeHigh] = useState(dayjs());

  // Calculation for our rangeLow Date
  const rangeLow = useMemo(
    () =>
      withReference(
        currentPeriod === "4 Weeks"
          ? ([27, "days"] as const)
          : currentPeriod === "7 Days"
          ? ([6, "days"] as const)
          : currentPeriod === "1 Year"
          ? ([11, "month"] as const)
          : ([5, "month"] as const),
        (args) => rangeHigh.subtract(args[0], args[1]),
      ),
    [rangeHigh, currentPeriod],
  );

  const periods = useMemo(
    () => [
      {
        value: "7 Days",
        label: "7 Days",
      },
      {
        value: "4 Weeks",
        label: "4 Weeks",
      },
      {
        value: "6 Months",
        label: "6 Months",
      },
      {
        value: "1 Year",
        label: "1 Year",
      },
    ],
    [],
  );

  const handleTabsChange = useCallback(
    (_, value: "Outdoor" | "Indoor"): void => {
      setCurrentTab(value);
    },
    [],
  );

  const [isActiveIndoor, setIsActiveIndoor] = useState(true);

  const handleClickIn = () => {
    setIsActiveIndoor(true);
    setIsActiveOutdoor(false);
  };

  const [isActiveOutdoor, setIsActiveOutdoor] = useState(false);

  const handleClickOut = () => {
    setIsActiveIndoor(false);
    setIsActiveOutdoor(true);
  };

  const handleTabsPeriodChange = useCallback(
    (_, value: "7 Days" | "4 Weeks" | "6 Months" | "1 Year"): void => {
      setCurrentPeriod(value);
    },
    [],
  );

  const handleCursor = useCallback(
    (opt: "prev" | "next") =>
      setRangeHigh((hi) =>
        withReference(
          currentPeriod === "4 Weeks"
            ? ([27, "days"] as const)
            : currentPeriod === "7 Days"
            ? ([1, "week"] as const)
            : currentPeriod === "1 Year"
            ? ([11, "month"] as const)
            : ([5, "month"] as const),
          (args) => hi[opt === "prev" ? "subtract" : "add"](args[0], args[1]),
        ),
      ),
    [currentPeriod],
  );

  const filterSessions = useMemo(
    () =>
      props.sessions
        .filter((session) => session.ride_types["name"] === currentTab)
        .filter(
          (session) =>
            session.athlete_bikes?.bike_types["name"] === props.bicycleType,
        )
        .filter((session) =>
          currentPeriod === "1 Year" || currentPeriod === "6 Months"
            ? rangeLow.isSameOrBefore(new Date(session.date), "month") &&
              rangeHigh.isSameOrAfter(new Date(session.date), "month")
            : rangeLow.isSameOrBefore(new Date(session.date), "day") &&
              rangeHigh.isSameOrAfter(new Date(session.date), "day"),
        )
        .filter(
          (session) =>
            !!session.session_statistics &&
            Object.keys(session.session_statistics).length > 0,
        ),

    [currentTab, rangeLow, rangeHigh],
  );

  // Finding All bikePositions
  const bikePositions = useMemo(() => {
    const bikes =
      props.bicycleType === "Road" ? roadBikePositions : ttBikePositions;
    return bikes.sort(
      (a, b) =>
        allBikePositions.indexOf(
          a as
            | "Standing"
            | "Comfortable - Not Ideal"
            | "Comfortable - Ideal"
            | "Aggressive - Not Ideal"
            | "Aggressive - Ideal"
            | "TT - Not Ideal"
            | "TT - Ideal"
            | "Lower than TT",
        ) -
        allBikePositions.indexOf(
          b as
            | "Standing"
            | "Comfortable - Not Ideal"
            | "Comfortable - Ideal"
            | "Aggressive - Not Ideal"
            | "Aggressive - Ideal"
            | "TT - Not Ideal"
            | "TT - Ideal"
            | "Lower than TT",
        ),
    );
  }, [filterSessions, props.defaultPositions]);

  //Chart labels. Finding the correct starting month
  const categories = useMemo(() => {
    if (currentPeriod === "1 Year" || currentPeriod === "6 Months") {
      const indexOfMonth = rangeLow.month();
      const months: Array<Month> = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return currentPeriod === "1 Year"
        ? [...months.slice(indexOfMonth), ...months.slice(0, indexOfMonth)]
        : [
            ...months.slice(indexOfMonth),
            ...months.slice(0, indexOfMonth),
          ].slice(0, 6);
    }
    if (currentPeriod === "7 Days" || currentPeriod === "4 Weeks") {
      const indexOfDay = rangeLow.day();

      const days: Array<Day> = [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
      ];
      return [...days.slice(indexOfDay), ...days.slice(0, indexOfDay)];
    }
    return [];
  }, [rangeLow, currentPeriod]);

  const buckets: { [K in Month]: { [P in BikePosition]: number } } =
    useMemo(() => {
      if (currentPeriod === "1 Year" || currentPeriod === "6 Months") {
        const sumObject = Object.fromEntries(
          categories.map((cat) => [
            cat,
            Object.fromEntries(
              bikePositions.map((pos: BikePosition) => [pos, 0]),
            ),
          ]),
        ) as { [K in Month]: { [P in BikePosition]: number } };

        // const countObject = Object.fromEntries(
        //   categories.map((cat) => [
        //     cat,
        //     Object.fromEntries(
        //       bikePositions.map((pos: BikePosition) => [pos, 0]),
        //     ),
        //   ]),
        // ) as { [K in Month]: { [P in BikePosition]: number } };

        
        const countObject = Object.fromEntries(
          categories.map((cat) => [
            cat,
            0
          ]),
        ) as { [K in Month]: number };


        for (const session of filterSessions) {
          const month = months[new Date(session.date).getMonth()];

          for (const statistic of session.session_statistics) {
            const displayName = ((position) =>
              position?.primary_name +
              (position?.secondary_name
                ? ` - ${position?.secondary_name}`
                : ""))(
              statistic?.default_position_id !== null
                ? props.defaultPositions.find(
                    (pos) => pos.id === statistic.default_position_id,
                  )
                : props.customPositions.find(
                    (pos) => pos.id === statistic.custom_position_id,
                  ),
            );
            sumObject[month][displayName] +=
              statistic.position_percentage * 100;
            }
            countObject[month] += 1;
        }

        return Object.fromEntries(
          Object.entries(sumObject).map(([month, val]) => [
            month,
            Object.fromEntries(
              Object.entries(val).map(([position, val]) => [
                position,
                val === 0
                  ? val
                  : (val / countObject[month]).toFixed(2),
              ]),
            ),
          ]),
        ) as { [K in Month]: { [P in BikePosition]: number } };
      }
      return {} as { [K in Month]: { [P in BikePosition]: number } };
    }, [categories, filterSessions, props.defaultPositions]);

  const day_buckets: { [K in Day]: { [P in BikePosition]: number } } =
    useMemo(() => {
      if (currentPeriod === "7 Days") {
        const dayObject = Object.fromEntries(
          categories.map((day) => [
            day,
            Object.fromEntries(
              bikePositions.map((pos: BikePosition) => [pos, 0]),
            ),
          ]),
        ) as { [K in Day]: { [P in BikePosition]: number } };

        // const countObject = Object.fromEntries(
        //   categories.map((day) => [
        //     day,
        //     Object.fromEntries(
        //       bikePositions.map((pos: BikePosition) => [pos, 0]),
        //     ),
        //   ]),
        // ) as { [K in Day]: { [P in BikePosition]: number } };


        const countObject = Object.fromEntries(
          categories.map((cat) => [
            cat,
            0
          ]),
        ) as { [K in Month]: number };

        for (const session of filterSessions) {
          const day = days[new Date(session.date).getDay()];

          for (const statistic of session.session_statistics) {
            const displayName = ((position) =>
              position?.primary_name +
              (position?.secondary_name
                ? ` - ${position?.secondary_name}`
                : ""))(
              statistic?.default_position_id !== null
                ? props.defaultPositions.find(
                    (pos) => pos.id === statistic.default_position_id,
                  )
                : props.customPositions.find(
                    (pos) => pos.id === statistic.custom_position_id,
                  ),
            );
            dayObject[day][displayName] += statistic.position_percentage * 100;
          }
          countObject[day] += 1;
        }

        return Object.fromEntries(
          Object.entries(dayObject).map(([day, val]) => [
            day,
            Object.fromEntries(
              Object.entries(val).map(([position, val]) => [
                position,
                val === 0 ? val : (val / countObject[day]).toFixed(2),
              ]),
            ),
          ]),
        ) as { [K in Day]: { [P in BikePosition]: number } };
      }
      return {} as { [K in Day]: { [P in BikePosition]: number } };
    }, [categories, filterSessions, props.defaultPositions]);

  const week_buckets: { [W in 1 | 2 | 3 | 4]: Week } = useMemo(() => {
    if (currentPeriod == "4 Weeks") {
      const emptyDayObject = Object.fromEntries(
        categories.map((day) => [
          day,
          Object.fromEntries(
            bikePositions.map((pos: BikePosition) => [pos, 0]),
          ),
        ]),
      ) as { [K in Day]: { [P in BikePosition]: number } };

      const week_buckets: { [W in 1 | 2 | 3 | 4]: Week } = {
        1: { ...emptyDayObject },
        2: { ...emptyDayObject },
        3: { ...emptyDayObject },
        4: { ...emptyDayObject },
      };

      for (let i = 1; i < 5; i++) {
        const date = rangeLow.add((i - 1) * 7, "day");
        // Filtering Sessions for Each Week
        const weekSession = filterSessions.filter(
          (session) =>
            rangeLow
              .add((i - 1) * 7, "day")
              .isSameOrBefore(new Date(session.date), "day") &&
            rangeLow
              .add(i * 7 - 1, "day")
              .isSameOrAfter(new Date(session.date), "day"),
        );

        const dayObject = Object.fromEntries(
          categories.map((day) => [
            day,
            Object.fromEntries(
              bikePositions.map((pos: BikePosition) => [pos, 0]),
            ),
          ]),
        ) as { [K in Day]: { [P in BikePosition]: number } };

        // const countObject = Object.fromEntries(
        //   categories.map((day) => [
        //     day,
        //     Object.fromEntries(
        //       bikePositions.map((pos: BikePosition) => [pos, 0]),
        //     ),
        //   ]),
        // ) as { [K in Day]: { [P in BikePosition]: number } };

        const countObject = Object.fromEntries(
          categories.map((cat) => [
            cat,
            0
          ]),
        ) as { [K in Month]: number };


        for (const session of weekSession) {
          const day = days[new Date(session.date).getDay()];
          for (const statistic of session.session_statistics) {
            const displayName = ((position) =>
              position?.primary_name +
              (position?.secondary_name
                ? ` - ${position?.secondary_name}`
                : ""))(
              statistic?.default_position_id !== null
                ? props.defaultPositions.find(
                    (pos) => pos.id === statistic.default_position_id,
                  )
                : props.customPositions.find(
                    (pos) => pos.id === statistic.custom_position_id,
                  ),
            );
            dayObject[day][displayName] += statistic.position_percentage * 100;
          }
          countObject[day] += 1;
        }

        const lastObject = Object.fromEntries(
          Object.entries(dayObject).map(([day, val]) => [
            day,
            Object.fromEntries(
              Object.entries(val).map(([position, val]) => [
                position,
                val === 0 ? val : (val / countObject[day]).toFixed(2),
              ]),
            ),
          ]),
        ) as { [K in Day]: { [P in BikePosition]: number } };

        week_buckets[i] = lastObject;
        delete Object.assign(week_buckets, {
          [date.format("DD/MM")]: week_buckets[i],
        })[i];
      }

      return week_buckets;
    }
    return {} as { [W in 1 | 2 | 3 | 4]: Week };
  }, [categories, filterSessions, props.defaultPositions]);

  const chartSeries: ApexAxisChartSeries = useMemo(() => {
    if (currentPeriod === "1 Year" || currentPeriod === "6 Months") {
      const positionNames = Object.keys(buckets[categories[0]]);
      const series = positionNames.map((positionName) => {
        const data = categories.map((category) => {
          return buckets[category][positionName];
        });
        return {
          name: positionName,
          data,
        };
      });
      return series;
    }
    if (currentPeriod == "7 Days") {
      const positionNames = Object.keys(day_buckets[categories[0]]);

      const series = positionNames.map((positionName) => {
        const data = categories.map((category) => {
          return day_buckets[category][positionName];
        });
        return {
          name: positionName,
          data,
        };
      });
      return series;
    }
    if (currentPeriod === "4 Weeks") {
      const weeksArray = Object.values(week_buckets);
      const combinedWeeks = weeksArray.reduce((acc, week) => {
        Object.keys(week).forEach((day) => {
          Object.keys(week[day]).forEach((position) => {
            if (!acc[position]) {
              acc[position] = [];
            }
            acc[position].push(week[day][position]);
          });
        });
        return acc;
      }, {});
      const series = Object.entries(combinedWeeks).map(([position, val]) => {
        return {
          name: position,
          data: val as any,
        };
      });
      return series;
    }
    return [];
  }, [day_buckets, buckets, , week_buckets]);

  const createChartOptions = useCallback(
    (id: string): ApexOptions => ({
      chart: {
        id,
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        background: "transparent",
      },
      noData: {
        text: "No Data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#FF5630",
          fontSize: "14px",
          fontFamily: undefined,
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
      },
      stroke: {
        curve: "smooth",
        dashArray: bikePositions.flatMap((position) =>
          position.includes("Not") ? [2] : [0],
        ),
        width: 2,
      },
      theme: {
        mode: theme.palette.mode,
      },
      fill: {
        opacity: 0,
        type: "solid",
      },
      markers: {
        hover: {
          sizeOffset: 2,
        },
        shape: "circle",
        size: 3,
        strokeWidth: 3,
        strokeOpacity: 1,
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return val + "%";
        },
      },
      grid: {
        strokeDashArray: 5,
        borderColor: theme.palette.divider,
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
          const hoverIndexes = w.globals.seriesX.map((seriesX) => {
            return seriesX.findIndex((xData) => xData === hoverXaxis);
          });
          let hoverList = "";
          hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
            if (hoverIndex >= 0) {
              if (series[seriesEachIndex][hoverIndex] !== undefined)
                hoverList += `
                        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                            <span class="apexcharts-tooltip-marker" style="background-color: ${
                              w.globals.markers.colors[seriesEachIndex]
                            };"></span>
                            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                <div class="apexcharts-tooltip-y-group">
                                    <span class="apexcharts-tooltip-text-y-label">${
                                      w.globals.seriesNames[seriesEachIndex]
                                    }: </span>
                                    <span class="apexcharts-tooltip-text-y-value">${w.globals.yLabelFormatters[0](
                                      series[seriesEachIndex][hoverIndex],
                                    )}</span>
                                </div>
                            </div>
                        </div>`;
            }
          });
          return `${hoverList}`;
        },
        x: {
          show: false,
        },
        followCursor: true,
      },
      yaxis: {
        show: true,
        min: 0,
        max: (val) => Math.ceil(val),
        labels: {
          formatter: (val) => Math.ceil(val) + "%",
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#64605D",
          },
        },
        tooltip: {
          enabled: false, // Hide the small tooltip for categories
        },
      },
      colors:
        props.bicycleType === "Road"
          ? roadBikeSeries.map((series) => allColors[series.name])
          : ttBikeSeries.map((series) => allColors[series.name]),
    }),
    [bikePositions],
  );

  const roadBikeSeries: ApexAxisChartSeries = useMemo(() => {
    if (props.bicycleType === "Road") {
      // roadBikePositions
      const chartSeriesData = bikePositions.map((name) => {
        const seriesObject = chartSeries.find((series) => series.name === name);
        if (seriesObject) {
          return { name: name, data: seriesObject.data };
        } else {
          return { name: name, data: [] };
        }
      });
      return chartSeriesData;
    } else return [];
  }, [chartSeries]);

  const ttBikeSeries: ApexAxisChartSeries = useMemo(() => {
    if (props.bicycleType === "TT") {
      // ttBikePositions
      const chartSeriesData = bikePositions.map((name) => {
        const seriesObject = chartSeries.find((series) => series.name === name);
        if (seriesObject) {
          return { name: name, data: seriesObject.data };
        } else {
          return { name: name, data: [] };
        }
      });
      return chartSeriesData;
    } else return [];
  }, [chartSeries]);

  const ROAD_BIKE_ChartOptions = useMemo(
    () => createChartOptions("RoadBike"),
    [day_buckets, buckets, , week_buckets],
  );

  const TT_BIKE_ChartOptions = useMemo(
    () => createChartOptions("TTBike"),
    [day_buckets, buckets, , week_buckets],
  );

  useEffect(() => {
    if (currentPeriod !== "4 Weeks" && props.bicycleType == "Road") {
      ApexCharts.exec("RoadBike", "updateOptions", {
        xaxis: {
          categories,
          tickAmount: undefined,
          overwriteCategories: undefined,
        },
      });
    } else if (currentPeriod === "4 Weeks" && props.bicycleType == "Road") {
      ApexCharts.exec("RoadBike", "updateOptions", {
        xaxis: {
          categories: [],
          tickAmount: 3,
          overwriteCategories:
            chartSeries.length !== 0
              ? Object.keys(week_buckets).reduce((arr, key) => {
                  return arr.concat(key, "", "", "", "", "", "", "");
                }, [])
              : [],
        },
      });
    } else if (currentPeriod !== "4 Weeks" && props.bicycleType == "TT") {
      ApexCharts.exec("TTBike", "updateOptions", {
        xaxis: {
          categories,
          tickAmount: undefined,
          overwriteCategories: undefined,
        },
      });
    } else if (currentPeriod === "4 Weeks" && props.bicycleType == "TT") {
      ApexCharts.exec("TTBike", "updateOptions", {
        xaxis: {
          categories: [],
          tickAmount: 3,
          overwriteCategories:
            chartSeries.length !== 0
              ? Object.keys(week_buckets).reduce((arr, key) => {
                  return arr.concat(key, "", "", "", "", "", "", "");
                }, [])
              : [],
        },
      });
    }
  }, [currentPeriod, categories, currentTab]);

  useEffect(() => {
    if (props.bicycleType == "TT")
      ApexCharts.exec("TTBike", "updateOptions", {
        yaxis: {
          labels: {
            formatter: (val) => Math.ceil(val) + "%",
          },
          show: bikePositions.length !== 0 ? true : false,
        },
        grid: {
          show: bikePositions.length !== 0 ? true : false,
        },
      });
    else {
      ApexCharts.exec("RoadBike", "updateOptions", {
        yaxis: {
          labels: {
            formatter: (val) => Math.ceil(val) + "%",
          },
          show: bikePositions.length !== 0 ? true : false,
        },
        grid: {
          show: bikePositions.length !== 0 ? true : false,
        },
      });
    }
  }, [chartSeries]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Tabs
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
          TabIndicatorProps={{
            style: { display: "none" },
          }}
        >
          <Tab
            icon={
              isActiveIndoor ? (
                <img
                  src={IndoorIcon}
                  style={{
                    filter:
                      "invert(36%) sepia(54%) saturate(1348%) hue-rotate(326deg) brightness(97%) contrast(88%)",
                  }}
                />
              ) : (
                <img
                  src={IndoorInIcon}
                  style={{
                    filter:
                      "invert(36%) sepia(54%) saturate(1348%) hue-rotate(326deg) brightness(97%) contrast(88%)",
                  }}
                />
              )
            }
            value={"Indoor"}
            sx={{ width: "25px", height: "20px", minWidth: "20px" }}
            disableRipple
            onClick={handleClickIn}
          />
          <Tab
            icon={
              isActiveOutdoor ? (
                <img
                  src={OutdoorActIcon}
                  style={{
                    filter:
                      "invert(36%) sepia(54%) saturate(1348%) hue-rotate(326deg) brightness(97%) contrast(88%)",
                  }}
                />
              ) : (
                <img
                  src={OutdoorIcon}
                  style={{
                    filter:
                      "invert(36%) sepia(54%) saturate(1348%) hue-rotate(326deg) brightness(97%) contrast(88%)",
                  }}
                />
              )
            }
            value={"Outdoor"}
            sx={{ width: "25px", height: "20px", minWidth: "100px" }}
            disableRipple
            onClick={handleClickOut}
          />
        </Tabs>
      </Box>
      <Card
        sx={{
          background: "#201B20",
          borderRadius: ".625rem",
          width: "98%",
          marginBottom: "1.25rem",
        }}
      >
        <Grid container px={"1.125rem"} justifyContent={"space-between"}>
          <Grid item alignSelf={"flex-end"}>
            <Typography variant="h5">
              {props.bicycleType === "Road" ? "Road Bike" : "TT Bike"}
            </Typography>
            <Grid item>
              <Typography sx={{ opacity: 0.5 }}>
                {currentPeriod == "1 Year"
                  ? "Average metrics over the last year"
                  : currentPeriod == "6 Months"
                  ? "Average metrics over the last six months"
                  : currentPeriod == "4 Weeks"
                  ? "Average metrics over the last four weeks"
                  : currentPeriod == "7 Days"
                  ? "Average metrics over the last seven days"
                  : ""}
              </Typography>
            </Grid>
          </Grid>

          <Grid item sx={{ display: "flex" }}>
            <IconButton
              color="primary"
              onClick={(_) => handleCursor("prev")}
              sx={{ color: "#DD4F4A" }}
            >
              <ArrowBackTwoToneIcon fontSize="small" />
            </IconButton>
            <Typography sx={{ opacity: 0.5, alignSelf: "center" }}>
              {currentPeriod === "1 Year" || currentPeriod === "6 Months"
                ? `${rangeLow.format("MMM YYYY")} - ${rangeHigh.format(
                    "MMM YYYY",
                  )}`
                : `${rangeLow.format("DD MMM YYYY")} - ${rangeHigh.format(
                    "DD MMM YYYY",
                  )}`}
            </Typography>
            <IconButton
              color="primary"
              onClick={(_) => handleCursor("next")}
              sx={{ color: "#DD4F4A" }}
            >
              <ArrowForwardTwoToneIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item>
            <Tabs
              onChange={handleTabsPeriodChange}
              value={currentPeriod}
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "& .MuiTab-root": {
                  border: ".0625rem solid #64605D",
                  color: "#64605D",
                  fontSize: ".75rem",
                  borderRadius: "6px",
                  minHeight: "30px",
                  marginTop: "10px",
                  padding: "7px 10px",
                  minWidth: "50px",
                },
                "& .Mui-selected": {
                  color: "#DD4F4A !important",
                  fontSize: ".75rem",
                  border: ".0625rem solid #DD4F4A",
                  borderRadius: "6px",
                  minHeight: "30px",
                },
              }}
            >
              {periods.map((period) => (
                <Tab
                  key={period.value}
                  label={period.label}
                  value={period.value}
                  sx={{ marginLeft: "10px" }}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>
        <Box sx={{ height: { xs: 500, xl: 770 } }}>
          {props.bicycleType == "Road" && (
            <>
              <Chart
                options={ROAD_BIKE_ChartOptions}
                series={roadBikeSeries}
                type="area"
                height={"92%"}
              />
            </>
          )}
          {props.bicycleType == "TT" && (
            <>
              <Chart
                options={TT_BIKE_ChartOptions}
                series={ttBikeSeries}
                type="area"
                height={"92%"}
              />
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ color: "#DD4F4A", paddingRight: "10px" }}
          >
            Ideal
          </Typography>
          <img src={IdealIcon} style={{ paddingRight: "10px" }} />
          <Typography
            variant="subtitle1"
            sx={{ color: "#DD4F4A", paddingRight: "10px" }}
          >
            Not Ideal
          </Typography>
          <img src={NotIdealIcon} style={{ paddingRight: "10px" }} />
        </Box>
      </Card>
    </>
  );
}
