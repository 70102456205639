import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import type { Database, Tables } from "src/utils/DatabaseDefinitions";
import MyRidesMap from "./MyRidesMap";
import { useCallback, useEffect, useMemo } from "react";
import IndoorRides from "../../../assets/img/views/dashboard-widgets/IndoorRides.png";
import { Link } from "react-router-dom";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import convert from "convert-units";
import { SupabaseCall, withReference } from "src/utils/common";
import CircularProgressBar from "../CircularProgressBar";
import GarminIcon from "../../../assets/img/views/logo/garminLogo.svg";
import StravaIcon from "../../../assets/img/views/logo/stravaLogo.svg";
import Darefore from "../../../assets/img/views/logo/miniLogo.svg";
import ManualIcon from "../../../assets/img/views/logo/manualLogoFit.svg";
import CDAIcon from "../../../assets/img/layout/cda.svg";
import AngleIcon from "../../../assets/img/layout/angle.svg";
import dayjs from "dayjs";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";

export type MyRidesCardProps = {
	metrics: Database["public"]["Tables"]["metrics"]["Row"][];
	sessions: Database["public"]["Tables"]["session"]["Row"] & {
		ride_types: Tables<"ride_types">;
		session_statistics: (Tables<"session_statistics"> & {
			custom_positions: Tables<"custom_positions">;
			default_positions: Tables<"default_positions">;
		})[];
	};
	ride_type: string;
};

export default function MyRidesCard(props: MyRidesCardProps) {
	const units = useUnits();
	const isMobileScreen = useMediaQuery("(max-width:440px)");
	const supabase = useSupabase();
	const user = useUser();
	const timeFormatter = useCallback((valStr) => {
		const val = Number(valStr);
		const hours = Math.floor(Math.floor(val / 60) / 60);
		const minutes = Math.trunc((val % 3600) / 60);
		const seconds = Math.trunc(val % 60);
		return `${hours}h ${minutes}m ${seconds}s`;
	}, []);

	const filteredMarkers = useCallback(
		(markers: [number, number][]) =>
			withReference(
				markers.findIndex((el) => !!el[0] && !!el[1]),
				(index) =>
					index === -1
						? []
						: markers
								.slice(0, index)
								.map((el, i, arr) => markers[index])
								.concat(markers.slice(index))
								.map((el, i, arr) =>
									!!el[0] && !!el[1]
										? el
										: arr.slice(0, i).reverse()[
												arr
													.slice(0, i)
													.reverse()
													.findIndex((el) => !!el[0] && !!el[1])
											],
								),
			),
		[],
	);
	const allFilteredMarkers = useMemo(
		() =>
			filteredMarkers(
				props.metrics
					.filter((metric) => metric.longitude !== 0)
					.map((metric) => [metric.latitude, metric.longitude]),
			),
		[props.metrics],
	);
	const SessionLogo = useMemo(() => {
		return props.sessions?.provider === "Darefore"
			? Darefore
			: props.sessions?.provider === "Strava"
				? StravaIcon
				: props.sessions?.provider === "Garmin"
					? GarminIcon
					: props.sessions?.provider === "Manual"
						? ManualIcon
						: null;
	}, [props.sessions]);

	const dominantPositionPromise = useCallback(
		() =>
			supabase
				.from("session_statistics")
				.select(
					"*,default_positions(primary_name,secondary_name),custom_positions(primary_name,secondary_name)",
				)
				.eq("session_id", props.sessions.id)
				.order("position_percentage", { ascending: false })
				.limit(1)
				.then((res) => {
					const data = res.data[0];

					const defaultNames = data?.default_positions;
					const customNames = data?.custom_positions;
					const hasCustom = data?.custom_positions;

					const primaryName = hasCustom
						? customNames?.primary_name
						: defaultNames?.primary_name;
					const secondaryName = hasCustom
						? customNames?.secondary_name
						: defaultNames?.secondary_name;
					const dominantPositionName =
						primaryName === undefined
							? "-"
							: secondaryName
								? `${primaryName} ${" "} ${secondaryName}`
								: primaryName;

					return dominantPositionName;
				}),
		[supabase],
	);

	const dominantPositionCall =
		useAsyncState<SupabaseCall<typeof dominantPositionPromise>>(); // prettier-ignore

	useEffect(() => {
		if (isUnloaded(dominantPositionCall)) {
			dominantPositionCall.fire(async () => dominantPositionPromise());
		}
	}, [dominantPositionCall]);
	return (
		<>
			{isFulfilled(dominantPositionCall) && (
				<Stack direction="row" alignItems={"center"} position={"relative"}>
					<img
						style={{ position: "absolute", top: -50, right: 0 }}
						src={SessionLogo}
						width={30}
						height={24}
						alt="Provider Logo"
					/>
					<CircularProgressBar
						colors={[
							{ value: 0, color: "rgb(221,79,74)" },
							{ value: 60, color: "rgb(226,142,84)" },
							{ value: 100, color: "rgb(54,179,126)" },
						]}
						percentage={(props.sessions.aero_score ?? 0) * 100}
					/>
					<Stack
						direction="column"
						width={"100%"}
						justifyContent={"center"}
						gap={1}
					>
						<Stack direction="column">
							<Typography
								sx={{
									fontSize: "20px",
									fontWeight: 700,
									color: "#BC6D29",
								}}
							>
								{units === "metric"
									? `${
											(props.sessions?.total_distance?.toFixed(0) ||
												undefined) ??
											"-"
										} km`
									: `${`${
											props.sessions?.total_distance
												? convert(props.sessions.total_distance)
														?.from("km")
														?.to("mi")
														?.toFixed(2)
												: "-"
										} mi`}`}
							</Typography>
							<Typography
								sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
							>
								{props.sessions?.recording_name ?? "-"}
							</Typography>
						</Stack>
						<Stack direction="column">
							<Typography
								sx={{
									fontSize: "13px",
									fontWeight: 700,
									color: "#BC6D29",
								}}
							>
								{dominantPositionCall.result}
							</Typography>
							<Typography
								sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
							>
								Position
							</Typography>
						</Stack>
						<Stack direction="row" gap={1}>
							<Stack direction="row" gap={0.5} alignItems="center">
								<img width={30} src={CDAIcon} aria-label="CDA" />
								<Stack direction="column">
									<Typography
										sx={{
											fontSize: "13px",
											fontWeight: 700,
											color: "#BC6D29",
											lineHeight: "14px",
										}}
									>
										{props.sessions?.cda ? props.sessions.cda.toFixed(3) : "-"}
									</Typography>
									<Typography
										sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
									>
										CDA
									</Typography>
								</Stack>
							</Stack>
							<Stack direction="row" gap={0.5} alignItems="center">
								<img width={30} src={AngleIcon} aria-label="Body Angle" />
								<Stack direction="column">
									<Typography
										sx={{
											fontSize: "13px",
											fontWeight: 700,
											color: "#BC6D29",
											lineHeight: "14px",
										}}
									>
										{props.sessions?.average_body_angle
											? `${props.sessions.average_body_angle.toFixed(0)}°`
											: "-"}
									</Typography>
									<Typography
										sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
									>
										Angle
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			)}

			{props.ride_type === "Outdoor" ? (
				<Box>
					{allFilteredMarkers.length === 0 ? (
						<img
							src={IndoorRides}
							height={140}
							width={"100%"}
							alt="Indoor Placeholder"
							style={{ borderRadius: "8px", objectFit: "cover" }}
						/>
					) : (
						<MyRidesMap markers={allFilteredMarkers} />
					)}
				</Box>
			) : (
				<img
					src={IndoorRides}
					height={140}
					width={"100%"}
					alt="Indoor Placeholder"
					style={{ borderRadius: "8px", objectFit: "cover" }}
				/>
			)}
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-around",
					marginTop: "5px",
				}}
			>
				<Grid
					container
					width={"50%"}
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "10px",
					}}
				>
					<Grid item>
						<Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
							{units === "metric"
								? `${
										(props.sessions?.average_speed?.toFixed(0) || undefined) ??
										"-"
									} kph`
								: `${
										props.sessions?.average_speed
											? (props.sessions?.average_speed * 0.6213711922)?.toFixed(
													0,
												)
											: "-"
									} mi`}
						</Typography>
						<Typography
							sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
						>
							Avg Speed
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
							{`${
								(props.sessions?.average_heart_rate?.toFixed(0) || undefined) ??
								"-"
							} bpm`}
						</Typography>
						<Typography
							sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
						>
							Average Heart Rate
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
							{units === "metric"
								? `${
										(props.sessions?.elevation_gain?.toFixed(0) || undefined) ??
										"-"
									} m`
								: `${`${
										props.sessions?.elevation_gain
											? convert(props.sessions.elevation_gain)
													?.from("m")
													?.to("ft")
													?.toFixed(0)
											: "-"
									} ft`}`}
						</Typography>
						<Typography
							sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
						>
							Elevation Gain
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
							{`${
								(props.sessions?.average_power?.toFixed(0) || undefined) ?? "-"
							} W`}
						</Typography>
						<Typography
							sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
						>
							Weighted Avg. Power
						</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					width={"50%"}
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "10px",
						alignItems: "flex-end",
						textAlign: "end",
					}}
				>
					<Grid item>
						<Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
							{`${
								props.sessions?.total_time
									? timeFormatter(props.sessions?.total_time / 1000)
									: "-"
							}`}
						</Typography>
						<Typography
							sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
						>
							Moving Time
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
							{`${
								(props.sessions?.average_cadence?.toFixed(0) || undefined) ??
								"-"
							} rpm`}
						</Typography>
						<Typography
							sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
						>
							Average Cadence
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
							{units === "metric"
								? `${
										(props.sessions?.elevation_loss?.toFixed(0) || undefined) ??
										"-"
									} m`
								: `${`${
										props.sessions?.elevation_loss
											? convert(props.sessions.elevation_loss)
													?.from("m")
													?.to("ft")
													?.toFixed(0)
											: "-"
									} ft`}`}
						</Typography>
						<Typography
							sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
						>
							Elevation Loss
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
							{props.sessions?.calories?.toFixed(0) ?? 0}
						</Typography>
						<Typography
							sx={{ fontSize: "11px", fontWeight: 400, opacity: "0.5" }}
						>
							kcals
						</Typography>
					</Grid>
				</Grid>
			</Box>
			<Typography
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					alignItems: "flex-start",
					marginTop: "0.4rem",
					fontSize: "15px",
					fontWeight: 700,
				}}
			>
				{dayjs(props.sessions.date).format("DD-MMM-YYYY")}
			</Typography>
			<Typography
				color={"white"}
				component={Link}
				to={`/dashboard/session/${props?.sessions?.id}`}
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-end",
					alignItems: "flex-end",
					marginTop: { xs: "0.4rem", xl: "5rem" },
				}}
			>
				View More
			</Typography>
		</>
	);
}
