import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import DesignerSidebar from "./DesignerSidebar";
import {
  DragEndEvent,
  useDndMonitor,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
import {
  ElementsType,
  FormElementInstance,
  FormElements,
} from "./FormElements";
import useDesigner from "./useDesigner";
import { nanoid } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import arrowBack from "../../../../assets/img/layout/coach/MyAthletes/arrowBack.svg";
import EditIcon from "../../../../assets/img/views/dashboard-library/renameWorkout.svg";
import { sortWorkoutSteps, timeFormatter, TSSEstimation } from "src/utils/common";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import { useSnackbar } from "notistack";
import { Tables } from "src/utils/DatabaseDefinitions";
import DeleteOutlined from "../../../../assets/img/views/dashboard-sessions/DeleteOutlined.svg";
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";

export type FormikProps = {
  name: string;
  duration: number;
  distance: number;
  tss: number;
  position_goal: string;
  bike_type: string;
  workout_type: string;
};
  
  export default function AssignedDesigner({assignedWorkout}: {assignedWorkout: Tables<'assigned_workout'> & { assigned_workout_step: Tables<'assigned_workout_step'>[]}}) {
    const supabase = useSupabase();
    const { enqueueSnackbar } = useSnackbar();
    const [openDialog, setOpenDialog] = useState(false)
    const units = useUnits();
    const navigate = useNavigate();
    const [rename, setRename] = useState<boolean>(false);
  
    const {
      removeElement,
      setBikeType,
      elements,
      setElements,
      addElement,
      selectedElement,
      intervalElements,
      setIntervalElements,
      addIntervalElement,
      addIntervalElements,
      setSelectedElement,
    } = useDesigner();
    // Formik
    const validationSchema = useMemo(() => yup.object().shape({}), []);
  
    const formik = useFormik<FormikProps>({
      initialValues: {
        name: assignedWorkout.name,
        duration: assignedWorkout.duration,
        distance: assignedWorkout.distance,
        tss: assignedWorkout.tss,
        bike_type: assignedWorkout.bike_type,
        position_goal: assignedWorkout.position_goal,
        workout_type: assignedWorkout.workout_type,
      },
      validationSchema,
      onSubmit: (values) => {
        if ("fire" in saveWorkoutCall) {
          saveWorkoutCall.fire(async () => saveWorkoutPromise(values));
        }
      },
    });

    useEffect(() => {
      const elements = []
			const intervalElements = []
			const sortedSteps = sortWorkoutSteps(assignedWorkout.assigned_workout_step)
			sortedSteps.forEach((step) => {
				const {id, created_at, ...rest} = step
				if(!step.group) {
					return elements.push({
						id: String(id),
						type: step.type as ElementsType,
						extraAttributes: rest,
					})
				}
				const group = elements.find((el) => el.id === step.group)
				if(group) {
					intervalElements.find((el) => el.id === group.id).elements.push({
						id: String(id),
						type: step.type as ElementsType,
						extraAttributes: rest,
					})
				} else {
					elements.push({id: step.group, intervalCounter: step.repeat, type: "Intervals"})
					intervalElements.push({
						id: step.group, 
							elements: [{
							id: String(id),
							type: step.type as ElementsType,
							extraAttributes: rest,
						}]
					})
				}
			})
			setElements(elements)
			setIntervalElements(intervalElements)
    }, []);

    const saveWorkoutPromise = useCallback(
      (values: FormikProps) =>
        supabase
          .from("assigned_workout")
          .update({
            ...values,
          })
          .eq("id", assignedWorkout.id)
          .select("id")
          .throwOnError()
          .then(({data}) => 
            supabase
            .from("assigned_workout_step")
            .delete()
            .eq("assigned_workout_id", assignedWorkout.id)
            .throwOnError()
            .then(() => 
              supabase
              .from("assigned_workout_step")
              .insert(
                elements.flatMap((element, i) => {
                  if (element.type !== "Intervals") {
                    return {
                      ...element.extraAttributes,
                      order: i + 1,
                      repeat: 1,
                      assigned_workout_id: assignedWorkout.id
                  }}
                  return intervalElements.find((intervalElement) => element.id === intervalElement.id).elements.map((el, index) => (
                    {
                      ...el.extraAttributes,
                      repeat: element.intervalCounter,
                      group: i + 1,
                      order: index + 1,
                      assigned_workout_id: assignedWorkout.id
                    }
                  ))
                })
              )
              .throwOnError()
            )
          ),
      [supabase, elements, intervalElements],
    );
  
    const saveWorkoutCall =
      useAsyncState<SupabaseCall<typeof saveWorkoutPromise>>();
  
    useEffect(() => {
      if (isFulfilled(saveWorkoutCall)) {
          enqueueSnackbar("Assigned Workout updated!", {
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
            variant: "success",
            autoHideDuration: 2000,
          });
        navigate("/dashboard/calendar")
      }
    }, [saveWorkoutCall]);

    const deleteAssignedWorkoutPromise = useCallback(
      (assigned_workout: number) =>
        supabase
          .from("assigned_workout")
          .delete()
          .eq("id", assigned_workout)
          .throwOnError()
          .then((_) => assigned_workout),
      [supabase],
    );
  
    const deleteAssignedWorkoutCall = useAsyncState<number>();
  
    useEffect(() => {
      if(isFulfilled(deleteAssignedWorkoutCall)) {
        navigate('/dashboard/calendar')
      }
    }, [deleteAssignedWorkoutCall]);
    
    const droppable = useDroppable({
      id: "designer-drop-area",
      data: {
        isDesignerDropArea: true,
      },
    });
  
    useDndMonitor({
      onDragEnd: (event: DragEndEvent) => {
        const { active, over } = event;
        if (!active || !over) return;
        const lastIndex = over.id.toString().lastIndexOf("-");
        const newId =
          lastIndex !== -1 ? over.id.toString().substring(0, lastIndex) : over.id;
  
        // Check if newId exists anywhere in the array of objects
        const isNewIdPresent = intervalElements.some((interval) => {
          return interval.elements.some((element) => {
            return element.id === newId;
          });
        });
        const intervalDropArea = over.id
          .toString()
          .includes("interval-drop-area");
        const checkIfDropOrInterval = isNewIdPresent || intervalDropArea;
  
        if (!checkIfDropOrInterval) {
          const isDesignerBtnElement = active.data?.current?.isDesignerBtnElement;
          const isDroppingOverDesignerDropArea =
            over.data?.current?.isDesignerDropArea;
  
          // First Scenario
          const droppingSidebarBtnOverDesignerDropArea =
            isDesignerBtnElement && isDroppingOverDesignerDropArea;
  
          if (droppingSidebarBtnOverDesignerDropArea) {
            const type = active.data?.current?.type;
            const id = nanoid();
  
            const newElement = FormElements[type as ElementsType].construct(id);
            addElement(elements.length, newElement);
            if (type === "Intervals") {
              addIntervalElement(newElement.id);
            }
            return;
          }
  
          // Second Scenario
          const isDroppingOverDesignerElementTopHalf =
            over.data?.current?.isTopHalfDesignerElement;
          const isDroppingOverDesignerElementBottomHalf =
            over.data?.current?.isBottomHalfDesignerElement;
          const isDroppingOverDesignerElement =
            isDroppingOverDesignerElementTopHalf ||
            isDroppingOverDesignerElementBottomHalf;
  
          const droppingSidebarBtnOverDesignerElement =
            isDesignerBtnElement && isDroppingOverDesignerElement;
  
          // Check if the elementId is inside interval or not
          const isInInterval = over.data?.current?.elementId;
          const flag = elements.findIndex((el) => el.id === isInInterval);
          if (droppingSidebarBtnOverDesignerElement && flag !== -1) {
            const type = active.data?.current?.type;
            const id = nanoid();
            const newElement = FormElements[type as ElementsType].construct(id);
  
            const overId = over.data?.current?.elementId;
            const overElementIndex = elements.findIndex((el) => el.id === overId);
            if (overElementIndex === -1) {
              throw new Error("element not found");
            }
  
            let indexForNewElement = overElementIndex;
            if (isDroppingOverDesignerElementBottomHalf) {
              indexForNewElement = overElementIndex + 1;
            }
            addElement(indexForNewElement, newElement);
            if (type === "Intervals") {
              addIntervalElement(newElement.id);
            }
            return;
          }
  
          // Third Scenario
          const isDraggingDesignerElement =
            active.data?.current?.isDesignerElement;
          const draggingDesignerElementOverAnotherDesignerElement =
            isDroppingOverDesignerElement && isDraggingDesignerElement;
  
          if (draggingDesignerElementOverAnotherDesignerElement) {
            const activeId = active.data?.current?.elementId;
            const overId = over.data?.current?.elementId;
  
            const activeElementIndex = elements.findIndex(
              (el) => el.id === activeId,
            );
  
            const intervalIndex = intervalElements.findIndex((element) =>
              element.elements.find((el) => el.id === activeId),
            );
            
            const overElementIndex = elements.findIndex((el) => el.id === overId);
            const flag =
              activeElementIndex === -1 || overElementIndex === -1 ? 1 : 0;
            removeElement(activeId);
  
            const activeElement =
              flag === 1
							  ? { ...intervalElements[intervalIndex].elements.find((el) => el.id === activeId) }
                : { ...elements[activeElementIndex] };
  
            let indexForNewElement = overElementIndex; // i assume i'm on top-half
            if (isDroppingOverDesignerElementBottomHalf) {
              indexForNewElement = overElementIndex + 1;
            }
  
            addElement(indexForNewElement, activeElement);
  
            if (activeElement.type === "Intervals") {
              const findIntervalElements = intervalElements.find(
                (interval) => interval.id === activeElement.id,
              ).elements;
              addIntervalElements(activeElement.id, findIntervalElements);
            }
          }
        }
      },
    });
  
    useEffect(() => {
      if (formik.values.bike_type === "TT" && formik.values.position_goal === "Aggressive")
        formik.setFieldValue("position_goal", "Free");
    }, [formik.values.bike_type]);
  
    useEffect(() => {
      setBikeType(formik.values.bike_type);
    }, [formik.values.bike_type]);
  
    // Use Effect to calculate total time,distance
    useEffect(() => {
      const time = elements.reduce((accumulator, current) => {
        const c = current.extraAttributes;
        return (
          accumulator +
          (current.type !== "Intervals"
          ? c.duration_type === "Time"
          ? c.duration
          : c.duration_type === "Lap"
          ? 0
          : (c.duration /
              (c.primary_target_type === "Speed"
                ? (Number(c.primary_target[0]) +
                    Number(c.primary_target[1])) /
                  2
                : c.secondary_target_type === "Speed"
                ? (Number(c.secondary_target[0]) +
                    Number(c.secondary_target[1])) /
                      2
                    : 27)) *
                3600
            : intervalElements
                .find((el) => el.id === current.id)
                .elements.reduce((acc, curr) => {
                  const c = curr.extraAttributes;
                  return (
                    acc +
                    (c.duration_type === "Time"
                    ? c.duration
                    : c.duration_type === "Lap"
                    ? 0
                    : (c.duration /
                        (c.primary_target_type === "Speed"
                          ? (Number(c.primary_target[0]) +
                              Number(c.primary_target[1])) /
                            2
                          : c.secondary_target_type === "Speed"
                          ? (Number(c.secondary_target[0]) +
                              Number(c.secondary_target[1])) /
                              2
                            : 27)) *
                        3600) *
                      current.intervalCounter
                  );
                }, 0))
        );
      }, 0);
  
      const distance = elements.reduce((accumulator, current) => {
        const c = current.extraAttributes;
        return (
          accumulator +
          (current.type !== "Intervals"
          ? c.duration_type === "Distance"
          ? c.duration
          : c.duration_type === "Lap"
          ? 0
          : (c.duration / 3600) *
            (c.primary_target_type === "Speed"
              ? (Number(c.primary_target[0]) +
                  Number(c.primary_target[1])) /
                2
              : c.secondary_target_type === "Speed"
              ? (Number(c.secondary_target[0]) +
                  Number(c.secondary_target[1])) /
                    2
                  : 27)
            : intervalElements
                .find((el) => el.id === current.id)
                .elements.reduce((acc, curr) => {
                  const c = curr.extraAttributes;
                  return (
                    acc +
                    (c.duration_type === "Distance"
                    ? c.duration
                    : c.duration_type === "Lap"
                    ? 0
                    : (c.duration / 3600) *
                      (c.primary_target_type === "Speed"
                        ? (Number(c.primary_target[0]) +
                            Number(c.primary_target[1])) /
                          2
                        : c.secondary_target_type === "Speed"
                        ? (Number(c.secondary_target[0]) +
                            Number(c.secondary_target[1])) /
                            2
                          : 27)) *
                      current.intervalCounter
                  );
                }, 0))
        );
      }, 0);
      const totalTss = elements.reduce((accumulator, current) => {
        if (!current.extraAttributes) {
          const intervalTss = intervalElements.find((intervalEl) => intervalEl.id === current.id).elements.reduce((acc, curr) => {
            const c = curr.extraAttributes;
            if ((c.primary_target_type === "Power" && c.primary_target_unit !== "ftp"))
              return accumulator;
            const duration =
              c.duration_type === "Time" ? c.duration : (c.duration * 3600) / 27;
            const elementTss = TSSEstimation(
              c.primary_target_type,
              c.primary_target[0],
              duration,
            );
            return acc + (elementTss * current.intervalCounter);
          }, 0)
          return accumulator + intervalTss
        }
  
        const c = current.extraAttributes;
        if ((c.primary_target_type === "Power" && c.primary_target_unit !== "ftp"))
          return accumulator;
        const duration =
          c.duration_type === "Time" ? c.duration : (c.duration * 3600) / 27;
        const elementTss = TSSEstimation(
          c.primary_target_type,
          c.primary_target[0],
          duration,
        );
        return accumulator + elementTss;
      }, 0);
      
      formik.setFieldValue('tss', Number(totalTss.toFixed(2)))
      formik.setFieldValue("duration", Math.ceil(time));
      formik.setFieldValue("distance", distance);
    }, [elements, intervalElements]);
  
    return (
      <>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              width: "96%",
              padding: "1em",
              background: "#201B20",
              borderRadius: ".625rem",
              marginTop: ".625rem",
              marginLeft: "1.5625rem",
              height: { xl: "1000px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{ padding: ".125rem" }}
                onClick={() => navigate("/dashboard/calendar")}
              >
                <img src={arrowBack} />
              </IconButton>
              <Typography>Back to Calendar</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "flex-end",
                marginRight: "1.25rem",
              }}
            >
              <Tooltip title="Remove from Calendar">
                <IconButton
                  sx={{paddingY: "5px", paddingX:"8px"}}
                  onClick={() => {
                    setOpenDialog(true)
                  }}
                >
                  <img src={DeleteOutlined} alt="delete-icon" width={"15px"} />
                </IconButton>
              </Tooltip>
              <Button
                variant="outlined"
                size="small"
                onClick={() => navigate("/dashboard/calendar")}
              >
                Cancel
              </Button>
  
              <Button variant="contained" size="small" type="submit">
                Save
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                paddingX: "1.875rem",
                marginBottom: ".625rem",
              }}
            >
              {rename ? (
                <FormControl
                  sx={{
                    "& .MuiFormControl-root": {
                      width: "250px",
                    },
                  }}
                >
                  <TextField
                    id="name"
                    name="name"
                    value={formik.values.name ?? ""}
                    onChange={formik.handleChange}
                    onBlur={() => setRename(false)}
                    autoFocus
                    onKeyDown={(e) =>
                      e.code === "Enter" ? setRename(false) : ""
                    }
                    sx={{
                      border: "1px solid #64605D",
                      borderRadius: "4px",
                      borderRightColor: "#A19D9A",
                      "& .MuiInputBase-root": {
                        width: "250px",
                        fontSize: "18px",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#BC6D29 !important",
                      },
                    }}
                  >
                    {formik.values.name}
                  </TextField>
                </FormControl>
              ) : (
                <Typography variant="h2">{formik.values.name}</Typography>
              )}
              {rename ? null : (
                <Tooltip title="Rename" placement="top" arrow>
                  <img
                    src={EditIcon}
                    width="14"
                    height="14"
                    onClick={() => setRename(true)}
                  />
                </Tooltip>
              )}
            </Box>
  
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "65%",
                  gap: 2,
                  marginTop: ".3125rem",
                  height: { xl: "820px", md: "520px" },
                }}
                onClick={() => {
                  if (selectedElement) setSelectedElement(null);
                }}
              >
                <Box
                  ref={droppable.setNodeRef}
                  sx={{
                    flex: 1,
                    border: droppable.isOver
                      ? "2px solid white"
                      : "1px solid black",
                    marginX: "30px",
                    borderRadius: "5px",
                    display: "flex",
                    flexdirecation: "column",
                    maxHeight: { xl: "850px", md: "520px" },
                    overflowY: "auto",
                    marginTop: ".3125rem",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    {/* When i have 0 elements */}
                    {!droppable.isOver && elements.length === 0 && (
                      <Box
                        sx={{
                          border: ".0625rem dashed #64605D",
                          background: "#2C262D",
                          borderRadius: ".375rem",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography>Drag and drop step here</Typography>
                      </Box>
                    )}
                    {/* While adding the first one */}
                    {droppable.isOver && elements.length === 0 && (
                      <Box
                        sx={{
                          background: "white",
                          width: "100%",
                          opacity: "0.25",
                          height: "100px",
                        }}
                      />
                    )}
                    {/* My elements */}
                    {elements.length > 0 && (
                      <Box>
                        {elements.map((element) => (
                          <DesignerElementWrapper
                            key={element.id}
                            element={element}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
  
              {/* RIGHT BOX */}
              <Box width={"35%"}>
                <Typography
                  variant="h2"
                  sx={{
                    marginBottom: { xl: "3rem", md: "12px" },
                    marginTop: "1rem",
                  }}
                >
                  Description Workout
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 10,
                    marginBottom: { xl: "2.5rem", md: ".3rem" },
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h4">Duration</Typography>
                    <Typography
                      sx={{
                        fontSize: "1.125rem",
                        fontWeight: 700,
                        color: "#BC6D29",
                      }}
                    >
                      {timeFormatter(formik?.values?.duration?.toString()) ??
                        "--"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h4">TSS</Typography>
                    <Typography
                      sx={{
                        fontSize: "1.125rem",
                        fontWeight: 700,
                        color: "#BC6D29",
                      }}
                    >
                      {formik.values.tss ?? "--"}
                    </Typography>
                  </Box>
  
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h4">Distance</Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: 700,
                        color: "#BC6D29",
                        marginBottom: "0.5rem",
                      }}
                    >
                      { 
                        units === "metric"
                        ? `${formik.values?.distance.toFixed(2) ?? "0"} km`
                        : `${
                            formik.values?.distance
                              ? convert(formik.values.distance)
                                  .from("km")
                                  .to("mi")
                                  .toFixed(2)
                              : "0"
                          } mi`
                      }
                    </Typography>
                  </Box>
                </Box>
  
                <Box sx={{ marginBottom: { xl: "2rem", md: "0.5px" } }}>
                  <Typography variant="h4" sx={{ marginBottom: { xl: "1rem" } }}>
                    Bike Type
                  </Typography>
                  <Select
                    id="bike_type"
                    name="bike_type"
                    value={formik.values.bike_type ?? ""}
                    onChange={formik.handleChange}
                    sx={{
                      color: "#F2F2F2",
                      "& .MuiSvgIcon-root": {
                        color: "#F2F2F2",
                      },
                      "&.MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#64605D",
                        },
                        "&:hover fieldset": {
                          borderColor: "#64605D",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#64605D",
                        },
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          border: ".0625rem solid #64605D",
                        },
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: "#64605D",
                          },
                        },
                      },
                      MenuListProps: {
                        disablePadding: true,
                      },
                    }}
                  >
                    <MenuItem value="Road">Road Bike</MenuItem>
                    <MenuItem value="TT">TT Bike</MenuItem>
                  </Select>
                </Box>
                <Box sx={{ marginBottom: { xl: "2rem", md: "0.5px" } }}>
                  <Typography variant="h4" sx={{ marginBottom: { xl: "1rem" } }}>
                    Position Goal
                  </Typography>
                  <FormControl>
                    <Select
                    id="position_goal"
                    name="position_goal"
                    value={formik.values.position_goal ?? ""}
                      onChange={formik.handleChange}
                      sx={{
                        color: "#F2F2F2",
                        "& .MuiSvgIcon-root": {
                          color: "#F2F2F2",
                        },
                        "&.MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#64605D",
                          },
                          "&:hover fieldset": {
                            borderColor: "#64605D",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#64605D",
                          },
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            border: ".0625rem solid #64605D",
                          },
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "#64605D",
                            },
                          },
                        },
                        MenuListProps: {
                          disablePadding: true,
                        },
                      }}
                    >
                      <MenuItem value="Free">Free</MenuItem>
                      <MenuItem value="Comfortable">Comfortable</MenuItem>
                      {formik.values.bike_type === "Road" && (
                        <MenuItem value="Aggressive">Aggressive</MenuItem>
                      )}
                      <MenuItem value="TT">TT</MenuItem>
                      <MenuItem value="Standing">Standing</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ marginBottom: { xl: "5rem", md: "0.5rem" } }}>
                  <Typography variant="h4" sx={{ marginBottom: { xl: "1rem" } }}>
                    Workout Type
                  </Typography>
                  <Select
                    id="workout_type"
                    name="workout_type"
                    value={formik.values.workout_type ?? ""}
                    onChange={formik.handleChange}
                    sx={{
                      color: "#F2F2F2",
                      "& .MuiSvgIcon-root": {
                        color: "#F2F2F2",
                      },
                      "&.MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#64605D",
                        },
                        "&:hover fieldset": {
                          borderColor: "#64605D",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#64605D",
                        },
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          border: ".0625rem solid #64605D",
                        },
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: "#64605D",
                          },
                        },
                      },
                      MenuListProps: {
                        disablePadding: true,
                      },
                    }}
                  >
                    <MenuItem value="Base">Base</MenuItem>
                    <MenuItem value="Recovery">Recovery</MenuItem>
                    <MenuItem value="Endurance">Endurance</MenuItem>
                    <MenuItem value="Intervals">Intervals</MenuItem>
                    <MenuItem value="Tempo">Tempo</MenuItem>
                  </Select>
                </Box>
                <Typography
                  variant="h2"
                  sx={{ marginBottom: { xl: "3rem", md: "1rem" } }}
                >
                  Add Steps
                </Typography>
                <DesignerSidebar />
              </Box>
            </Box>
          </Box>
        </form>
        <Modal open={openDialog} onClose={setOpenDialog}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#2A252B",
            border: "3px solid #BC6D29",
            borderRadius: "6px",
            p: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="coach700" sx={{color: "#BC6D29"}} component={"h2"} mt={4}>
            Remove Assigned Workout
          </Typography>
          <Typography component="h2" mt={4}>
            Are you sure you want to remove this assigned workout?
          </Typography>
          <Box sx={{ mt: 6, display: "flex", gap: 2 }}>
            <Button
              onClick={() => setOpenDialog(false)}
              variant="outlined"
              size="small"
              sx={{ width: "155px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={(_) => {
                if (isUnloaded(deleteAssignedWorkoutCall)) {
                  deleteAssignedWorkoutCall.fire(async () => deleteAssignedWorkoutPromise(assignedWorkout.id));
                }
              }}            
              variant="contained"
              size="small"
              sx={{ width: "155px" }}
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Modal>
      </>
    );
  }
  
  function DesignerElementWrapper({ element }: { element: FormElementInstance }) {
    const DesignerElement = FormElements[element.type].designerComponent;
  
    const topHalf = useDroppable({
      id: element.id + "-top",
      data: {
        type: element.type,
        elementId: element.id,
        isTopHalfDesignerElement: true,
      },
    });
  
    const bottomHalf = useDroppable({
      id: element.id + "-bottom",
      data: {
        type: element.type,
        elementId: element.id,
        isBottomHalfDesignerElement: true,
      },
    });
  
    const draggable = useDraggable({
      id: element.id + "-drag-handler",
      data: {
        type: element.type,
        elementId: element.id,
        isDesignerElement: true,
      },
    });
  
    if (draggable.isDragging) return null; // temporary remove the element from designer
  
    return (
      <Box
        ref={draggable.setNodeRef}
        {...draggable.listeners}
        {...draggable.attributes}
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100%",
          margin: "10px",
          touchAction:"none",
          WebkitTouchCallout:"none",
          WebkitUserSelect:"none",
          msUserSelect:"none",
          userSelect:"none"
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "20%",
            borderRadius: "4px",
          }}
          ref={topHalf.setNodeRef}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "20%",
            borderRadius: "4px",
          }}
          ref={bottomHalf.setNodeRef}
        />
  
        {topHalf.isOver && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              width: "100%",
              borderRadius: "6px",
              height: "7px",
              backgroundColor: "#BC6D29",
              borderBottomLeftRadius: "none",
              borderBottomRightRadius: "none",
              display: topHalf.isOver ? "block" : "none",
              // zIndex: 100,
            }}
          />
        )}
        <Box sx={{ flex: 1, width: "100%" }}>
          <DesignerElement
            elementInstance={element}
            listeners={draggable.listeners}
            attributes={draggable.attributes}
          />
        </Box>
        {bottomHalf.isOver && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              borderRadius: "6px",
              height: "7px",
              backgroundColor: "#BC6D29",
              borderBottomLeftRadius: "none",
              borderBottomRightRadius: "none",
              display: bottomHalf.isOver ? "block" : "none",
            }}
          />
        )}
      </Box>
    );
  }