import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import type { TableRow } from "src/contexts/CacheContext";
import {
	type BikePosition,
	allBikePositions,
	allColors,
} from "src/utils/common";
import type { PositionStatistics } from "src/utils/types";
import StanceInfoCardV3 from "../customComponents/StanceInfoCardV3";
import { useRideSession } from "../customPages/SessionViewsPreload";
import BlurAnalytics from "../../assets/img/views/position-images/blurAnalytics.svg";

export type PositionsViewProps = {
	defaultPositions: Array<TableRow<"default_positions">>;
	customPositions: Array<TableRow<"custom_positions">>;
};

export default function PositionsView(props: PositionsViewProps) {
	const session = useRideSession();

	const positionStatistics: Array<
		Omit<PositionStatistics, "positionType"> & {
			positionType: string;
		}
	> = useMemo(() => {
		try {
			return session.session_statistics
				.map((stat) => ({
					...stat,
					positionType: ((position) =>
						position.primary_name +
						(position.secondary_name ? ` - ${position.secondary_name}` : ""))(
						stat.default_position_id !== null
							? props.defaultPositions.find(
									(pos) => pos.id === stat.default_position_id,
								)
							: props.customPositions.find(
									(pos) => pos.id === stat.custom_position_id,
								),
					),
				}))
				.sort(
					(a, b) =>
						allBikePositions.indexOf(
							a.positionType as
								| "Standing"
								| "Comfortable - Not Ideal"
								| "Comfortable - Ideal"
								| "Aggressive - Not Ideal"
								| "Aggressive - Ideal"
								| "TT - Not Ideal"
								| "TT - Ideal"
								| "Lower than TT",
						) -
						allBikePositions.indexOf(
							b.positionType as
								| "Standing"
								| "Comfortable - Not Ideal"
								| "Comfortable - Ideal"
								| "Aggressive - Not Ideal"
								| "Aggressive - Ideal"
								| "TT - Not Ideal"
								| "TT - Ideal"
								| "Lower than TT",
						),
				);
		} catch (err) {
			return null;
		}
	}, [props.defaultPositions, props.customPositions]);

	const firstTab = useMemo(() => positionStatistics?.[0]?.positionType, []);
	const [positionValue, setPositionValue] = useState(firstTab);
	const handleChangePos = (_, newValue: BikePosition) => {
		setPositionValue(newValue);
	};

	return positionStatistics.length !== 0 ? (
		<Box
			sx={{
				width: "98%",
				background: "#201B20",
				borderRadius: "6px",
				height: { xs: "1350px", sm: "920px", md: "600px" },
				display: "flex",
				flexDirection: "column",
				marginTop: "24px",
			}}
		>
			<Box
				sx={{
					width: "100%",
					background:
						"linear-gradient(62.63deg, rgba(221,79,74,0.1) 17.99%, rgba(209,89,62,0.1) 45.99%, rgba(188,109,41,0.1) 82%)",
					boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
					borderTopLeftRadius: "6px",
					borderTopRightRadius: "6px",
					height: { xs: "320px", sm: "188px" },
				}}
			>
				<Tabs
					onChange={handleChangePos}
					value={positionValue}
					variant="scrollable"
					// textColor="primary"
					// indicatorColor="primary"
					TabIndicatorProps={{
						style: { display: "none" },
					}}
					sx={{
						"& .MuiTab-root": {
							color: "#F7F3F0",
							fontSize: ".8125rem",
							fontWeight: 400,
							height: "100px",
							borderRadius: "6px 0px 32px",
							flexGrow: 1,
							// width: "25%",
						},
						"& .Mui-selected": {
							border: ".0625rem solid #BC6D29",
							background:
								"linear-gradient(62.63deg, #DD4F4A 17.99%, #D1593E 45.99%, #BC6D29 82%)",
							boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
							color: "#F7F3F0",
							fontSize: ".9375rem",
							fontWeight: 700,
							borderRadius: "6px 0px 32px",
							flexGrow: 1,
						},
					}}
				>
					{positionStatistics.map((tab, index) => (
						<Tab
							key={index}
							label={
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
									}}
								>
									{positionValue === tab?.positionType ? (
										<>
											<Box
												sx={{
													diplay: "flex",
													alignSelf: "flex-end",
													justifyContent: "flex-end",
													marginTop: ".5rem",
													// marginTop: "1.5rem",
												}}
											>
												<Typography
													sx={{
														alignSelf: "flex-end",
														display: "inline-block",
														backgroundColor: `${allColors[positionValue]}`,
														borderRadius: "50%",
														width: "13px",
														height: "13px",
													}}
												/>
											</Box>
											<Typography
												sx={{
													color: "#F7F3F0",
													fontSize: ".6875rem",
													fontWeight: 400,
													marginBottom: "11px",
												}}
											>
												POSITION
											</Typography>
										</>
									) : (
										""
									)}

									<Typography
										sx={{
											fontSize: ".9375rem",
											color: "#F7F3F0",
											marginBottom: "32px",
										}}
									>
										{tab?.positionType}
									</Typography>
								</Box>
							}
							value={tab?.positionType}
						/>
					))}
				</Tabs>
				<Box
					sx={{
						width: "100%",
					}}
				>
					{positionStatistics.length !== 0 && (
						<StanceInfoCardV3
							statistics={positionStatistics}
							currentTabStat={positionValue}
							totalTime={session.total_time}
						/>
					)}
				</Box>
			</Box>
		</Box>
	) : (
		<img src={BlurAnalytics} width={"85%"} alt="blur-analytics" />
	);
}
