import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SubscriptionComponent from "./SubscriptionComponent";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useMemo, useState } from "react";
import { useSession, useSupabase } from "src/contexts/SupabaseContext";
import SubscriptionForm from "./SubscriptionForm";
import dayjs from "dayjs";
import type { Tables } from "src/utils/DatabaseDefinitions";
import { isFulfilled } from "src/utils/Async";

export type SubscriptionViewProps = {
  athleteSubscription?: Tables<"athlete_subscription">;
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const priceInfo = {
  monthly: {
    priceId: process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID,
    amount: 999,
  },
  yearly: {
    priceId: process.env.REACT_APP_STRIPE_YEARLY_PRICE_ID,
    amount: 9599,
  },
};

export default function SubscriptionView(props: SubscriptionViewProps) {
  const supabase = useSupabase();
  const theme = useTheme();
  const session = useSession();
  const [period, setPeriod] = useState("monthly");
  const [showForm, setShowForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const breakpoint = theme.breakpoints.down("md");
  const isMobileScreen = useMediaQuery("(max-width:480px)");

  const registrationDate = useMemo(() => {
    if (isFulfilled(session))
      return session.result.data.session.user.created_at;
  }, [session]);

  return (
    <Box
      sx={{
        width: "98%",
        background: "#201B20",
        borderRadius: ".625rem",
        paddingY: "1.5em",
        paddingX: isMobileScreen ? "1rem" : "2rem",
        minHeight: "70vh",
      }}
    >
      <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography sx={{ fontSize: "13px" }}>Member Since:</Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
            {dayjs(registrationDate).format("DD MMMM YYYY")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography sx={{ fontSize: "13px" }}>Membership Status:</Typography>
          <Typography sx={{ fontSize: "13px", color: "#BC6D29" }}>
            {props.athleteSubscription?.status === "active"
              ? "PREMIUM"
              : "BASIC"}
          </Typography>
          {props.athleteSubscription?.expire_at && (
            <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
              Expires at{" "}
              {dayjs(props.athleteSubscription.expire_at).format(
                "DD MMMM YYYY",
              )}
            </Typography>
          )}
          {props.athleteSubscription?.provider === "stripe" &&
            (props.athleteSubscription?.cancel_at_period_end === false ? (
              <Button
                variant="coachVariant"
                onClick={() => setOpenModal(true)}
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  fontWeight: "normal",
                  fontSize: "12px",
                  color: "#DD4F4A",
                }}
              >
                Cancel Subscription
              </Button>
            ) : (
              <Typography
                sx={{ fontSize: "12px", fontWeight: "400", color: "#DD4F4A" }}
              >
                Your subscription is canceled you still have until the
                expiration date to use our features.
              </Typography>
            ))}
        </Box>
      </Box>
      {showForm ? (
        <Elements
          stripe={stripePromise}
          options={{
            mode: "subscription",
            amount: priceInfo[period].amount,
            currency: "eur",
            paymentMethodCreation: "manual",
            locale: "en",
            appearance: {
              theme: "night",
              variables: {
                colorDanger: "#cd3b3b",
                colorPrimary: "#BC6D29",
              },
            },
          }}
        >
          <SubscriptionForm
            onClick={(confirmationToken) =>
              supabase.functions.invoke("create-stripe-subscription", {
                body: {
                  priceId: priceInfo[period].priceId,
                  confirmationTokenId: confirmationToken.id,
                },
              })
            }
          />
        </Elements>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 3,
            [breakpoint]: {
              flexDirection: "column",
            },
          }}
        >
          <SubscriptionComponent
            sub_type="Basic"
            features={[
              "Cycling position tracking",
              "Cycling position analysis",
              "Speed estimation",
              "Training calendar",
              "Performance analysis",
              "Integration with 3rd party platforms",
              "Import fit files",
            ]}
            features_info="See all features"
            pricing="FREE"
            canBuy={false}
            status={props.athleteSubscription?.status !== "active"}
          />
          <SubscriptionComponent
            sub_type="Premium"
            features={[
              "Training planning",
              "Performance prediction",
              "Workout library",
              "Enhanced analysis",
              "Set custom positions",
              "Connect to a Coach",
            ]}
            features_info="See all features"
            pricing={`€${priceInfo[period].amount / 100}`}
            pricing_info="Per active user"
            buttonText={
              props.athleteSubscription?.status === "active"
                ? "Subscribed"
                : "Subscribe"
            }
            canBuy={
              !props.athleteSubscription ||
              props.athleteSubscription?.status === "incomplete"
            }
            status={props.athleteSubscription?.status === "active"}
            period={period}
            setPeriod={setPeriod}
            onClick={() => setShowForm(true)}
          />
        </Box>
      )}
      <Modal open={openModal} onClose={setOpenModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#2A252B",
            border: "3px solid #DD4F4A",
            borderRadius: "6px",
            p: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="coach700" component="h2">
            Cancel Subscription
          </Typography>
          <Typography component="h2" mt={4}>
            Are you certain you want to cancel your subscription?
          </Typography>
          <Typography component="h2" mt={1} sx={{ textAlign: "center" }}>
            Please note that canceling will prevent your subscription from
            renewing, but you'll continue to have access until your current
            subscription expires.
          </Typography>
          <Typography component="h2" mt={1}>
            Refunds are not available. If you have any concerns, please contact
            our customer support team at support@darefore.com.
          </Typography>
          <Box sx={{ mt: 6, display: "flex", gap: 2 }}>
            <Button
              onClick={() => setOpenModal(false)}
              variant="coachVariant2"
              size="small"
              sx={{ width: "155px", color: "#fff", borderColor: "#fff" }}
            >
              No
            </Button>
            <Button
              onClick={async (_) => {
                setIsCanceling(true);
                const res = await supabase.functions.invoke(
                  "cancel-stripe-subscription",
                );
                if (!res.error) {
                  return window.location.reload();
                }
                setIsCanceling(false);
              }}
              variant="coachVariant"
              size="small"
              sx={{ width: "155px" }}
              disabled={isCanceling}
            >
              {isCanceling ? (
                <CircularProgress
                  size={20}
                  disableShrink
                  thickness={3}
                  style={{ color: "#FFF" }}
                />
              ) : (
                "Yes"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
